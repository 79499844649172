import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { omitBy, isNil, isEmpty } from 'lodash';
import { Text } from 'rebass/styled-components';
import { DataExported, RequestsReportingConfig } from '@deepstream/common/reporting';
import { EditableGridColumn } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { getValidSorting } from '@deepstream/ui-kit/grid/EditableGrid/useMultiSortGridRows';
import { ModalForm } from '../../ModalForm';
import { RadioField } from '../../form/RadioField';
import useDownload from '../../useDownload';
import { useCurrentUserLocale } from '../../useCurrentUser';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useSelectedCompanyIds } from '../../selectedCompanyIds';
import { AllRequestsGridDisplayConfig } from './allRequestsGridTypes';
import { useAllRequestsReportingColumnConfigById } from './useAllRequestsGridColumnConfigById';
import { allRequestsGridAvailableColumnIds } from './allRequestsGridViews';

export const DownloadAllRequestsReportModal = ({
  isOpen,
  close,
  gridConfig,
  config,
  visibleColumns,
}: {
  isOpen: boolean;
  close: () => void;
  gridConfig: AllRequestsGridDisplayConfig;
  config: RequestsReportingConfig;
  visibleColumns: EditableGridColumn<any>[];
}) => {
  const { t } = useTranslation(['translation', 'general']);
  const download = useDownload();
  const locale = useCurrentUserLocale();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const columnConfigById = useAllRequestsReportingColumnConfigById();
  const selectedCompanyIds = useSelectedCompanyIds();

  const { dataShown, currency } = config;

  const filterQuery = useMemo(() => {
    const filters = gridConfig.filters.data;
    return Object.entries(filters).reduce((acc, [columnId, value]) => {
      acc[columnId] = columnConfigById[columnId].getQueryParam ? columnConfigById[columnId].getQueryParam(value) : value;
      return acc;
    }, {});
  }, [columnConfigById, gridConfig.filters.data]);

  const downloadReport = useCallback(
    async ({ dataExported }: { dataExported: DataExported }) => {
      const visibleColumnIds = visibleColumns.map((column) => column._id);

      const validSorting = getValidSorting(gridConfig.sorting.selectedSorting, visibleColumns);
      const sort = validSorting.map(({ criterion, direction }) => ({
        accessor: criterion.accessor,
        direction: direction.direction,
      }));

      const queryParams = new URLSearchParams(omitBy(
        {
          locale,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          dataShown,
          currency,
          selectedCompanyIds: JSON.stringify(selectedCompanyIds),
          visibleColumnIds: dataExported === DataExported.VISIBLE_ONLY
            ? JSON.stringify(visibleColumnIds)
            : JSON.stringify(allRequestsGridAvailableColumnIds),
          ...(!isEmpty(sort) && { sort: JSON.stringify(sort) }),
          ...(dataExported === DataExported.VISIBLE_ONLY && { filter: JSON.stringify(omitBy(filterQuery, isNil)) }),
        } as Record<string, string>,
        isNil,
      ));

      try {
        await download(`/ajax/company/${currentCompanyId}/reporting/requests/allRequests/download-xlsx?${queryParams.toString()}`);
        close();
      } catch (error) { } // eslint-disable-line no-empty
    },
    [
      currency,
      currentCompanyId,
      dataShown,
      download,
      filterQuery,
      gridConfig.sorting.selectedSorting,
      locale,
      selectedCompanyIds,
      visibleColumns,
      close,
    ],
  );

  return (
    <ModalForm
      heading={t('request.allRequestsTable.downloadReportModal.title')}
      initialValues={{
        dataExported: DataExported.VISIBLE_ONLY,
      }}
      validationSchema={
        yup.object().shape({
          dataExported: yup.string().oneOf(['visibleOnly', 'allAvailable']).required(t('general.required')),
        })
      }
      isOpen={isOpen}
      onCancel={close}
      onSubmit={downloadReport}
      disableSubmitIfNotDirty={false}
      disableSubmitIfInvalid={false}
      submitLabel={t('downloadXLSX', { ns: 'general' })}
      style={{ content: { width: '500px' } }}
    >
      <Text>
        {t('request.allRequestsTable.downloadReportModal.description')}
      </Text>

      <RadioField
        name="dataExported"
        options={[
          {
            value: DataExported.VISIBLE_ONLY,
            label: t('request.allRequestsTable.downloadReportModal.visibleOnly'),
            description: t('request.allRequestsTable.downloadReportModal.visibleOnlyDescription'),
          },
          {
            value: DataExported.ALL_AVAILABLE,
            label: t('request.allRequestsTable.downloadReportModal.allAvailable'),
            description: t('request.allRequestsTable.downloadReportModal.allAvailableDescription'),
          },
        ]}
        gap={2}
      />
    </ModalForm>
  );
};
