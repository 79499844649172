import { createContext, useContext, useMemo, useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { keyBy } from 'lodash';
import { getRequestTagAncestorsNames, RequestTag } from '@deepstream/common/rfq-utils';
import { useApi, wrap } from '../../api';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useCurrentUserLocale } from '../../useCurrentUser';

type RequestTagsContextValue = {
  tags: RequestTag[];
  tagById: { [id: string]: RequestTag };
  isLoading: boolean;
  addTag: (tag: RequestTag) => Promise<void>;
  removeTag: (tag: RequestTag) => Promise<void>;
  updateTag: (tag: RequestTag) => Promise<void>;
};

export const RequestTagsContext = createContext<RequestTagsContextValue>({
  tags: [],
  tagById: {},
  isLoading: false,
  // @ts-expect-error ts(2322) FIXME: Type 'Promise<null>' is not assignable to type 'Promise<void>'.
  addTag: async () => null,
  // @ts-expect-error ts(2322) FIXME: Type 'Promise<null>' is not assignable to type 'Promise<void>'.
  updateTag: async () => null,
  // @ts-expect-error ts(2322) FIXME: Type 'Promise<null>' is not assignable to type 'Promise<void>'.
  removeTag: async () => null,
});

export const useApiRequestTags = (companyId: string) => {
  const api = useApi();
  const { data, isLoading } = useQuery(['requestTags', { companyId }], wrap(api.getCompanyRequestTags));

  return {
    tags: data || [],
    isLoading,
  };
};

export type AddRequestTagParams = {
  name: string;
  parentTagId?: string;
};

export const useApiAddRequestTag = (companyId: string) => {
  const api = useApi();
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation(({ companyId, requestTag }: { companyId: string; requestTag: AddRequestTagParams; }) => {
    return api.createCompanyRequestTag({ companyId, requestTag });
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('requestTags');
    },
  });

  return {
    addTag: (requestTag: AddRequestTagParams) => mutateAsync({ companyId, requestTag }),
    isLoading,
  };
};

export const useApiRemoveRequestTag = (companyId: string) => {
  const api = useApi();
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation(({ companyId, requestTagId }: { companyId: string; requestTagId: string; }) => {
    return api.deleteCompanyRequestTag({ companyId, requestTagId });
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('requestTags');
    },
  });

  return {
    removeTag: (requestTagId: string) => mutateAsync({ companyId, requestTagId }),
    isLoading,
  };
};

export type UpdateRequestTagParams = {
  _id: string;
  name: string;
  parentTagId?: string;
  requestIds?: string[];
  contractIds?: string[];
};

export const useApiUpdateRequestTag = (companyId: string) => {
  const api = useApi();
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation(({ companyId, requestTag }: { companyId: string; requestTag: UpdateRequestTagParams; }) => {
    return api.updateCompanyRequestTag({ companyId, requestTag });
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('requestTags');
    },
  });

  return {
    updateTag: (requestTag: UpdateRequestTagParams) => mutateAsync({ companyId, requestTag }),
    isLoading,
  };
};

export const useRequestTagsContext = () => useContext(RequestTagsContext);

export const RequestTagsProvider = ({ children }) => {
  const currentCompanyId = useCurrentCompanyId();
  // @ts-expect-error ts(2345) FIXME: Argument of type 'string | null' is not assignable to parameter of type 'string'.
  const { tags } = useApiRequestTags(currentCompanyId);
  const tagById = useMemo(() => keyBy(tags, '_id'), [tags]);
  const queryClient = useQueryClient();
  // @ts-expect-error ts(2345) FIXME: Argument of type 'string | null' is not assignable to parameter of type 'string'.
  const { addTag: apiAddTag, isLoading: isAddingTag } = useApiAddRequestTag(currentCompanyId);
  // @ts-expect-error ts(2345) FIXME: Argument of type 'string | null' is not assignable to parameter of type 'string'.
  const { removeTag: apiRemoveTag, isLoading: isRemovingTag } = useApiRemoveRequestTag(currentCompanyId);
  // @ts-expect-error ts(2345) FIXME: Argument of type 'string | null' is not assignable to parameter of type 'string'.
  const { updateTag: apiUpdateTag, isLoading: isUpdatingTag } = useApiUpdateRequestTag(currentCompanyId);

  const locale = useCurrentUserLocale();
  const orderedTags = useMemo(() => {
      const tagsWithAncestorNames = tags.reduce((acc, tag) => ({
        ...acc,
        [tag._id]: getRequestTagAncestorsNames(tag, tagById).join('') + tag.name,
      }), {});

      return tags.sort((a, b) => tagsWithAncestorNames[a._id].localeCompare(tagsWithAncestorNames[b._id], locale));
    },
    [tags, tagById, locale],
  );

  const invalidateQueries = useCallback(
    () => {
      queryClient.invalidateQueries('receivedRequests');
      queryClient.invalidateQueries('sentRequests');
      queryClient.invalidateQueries('receivedContracts');
      queryClient.invalidateQueries('sentContracts');
      queryClient.invalidateQueries('draftContracts');
    },
    [queryClient],
  );

  const addTag = useCallback(
    async (tag: RequestTag) => {
      const { name, parentTagId } = tag;
      // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
      await apiAddTag({ name, parentTagId });
      invalidateQueries();
    },
    [apiAddTag, invalidateQueries],
  );

  const updateTag = useCallback(
    async (tag: RequestTag) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { name, parentTagId, _id, requestIds, contractIds } = tag;
      // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
      await apiUpdateTag({ name, parentTagId, _id, requestIds, contractIds });
      invalidateQueries();
    },
    [apiUpdateTag, invalidateQueries],
  );

  const removeTag = useCallback(
    async (tag: RequestTag) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { _id } = tag;
      // @ts-expect-error ts(2345) FIXME: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
      await apiRemoveTag(_id);
      invalidateQueries();
    },
    [apiRemoveTag, invalidateQueries],
  );

  const isLoading = isAddingTag || isRemovingTag || isUpdatingTag;

  const contextValue = useMemo(
    () => ({
      tags: orderedTags,
      tagById,
      isLoading: false && isLoading,
      addTag,
      updateTag,
      removeTag,
    }),
    [orderedTags, tagById, isLoading, addTag, updateTag, removeTag],
  );

  return (
    <RequestTagsContext.Provider value={contextValue}>
      {children}
    </RequestTagsContext.Provider>
  );
};
