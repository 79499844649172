import { useMemo } from 'react';
import { get, capitalize } from 'lodash';
import { useQueryClient, useQuery } from 'react-query';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { PropertyList, PropertyListAction, Skip } from '@deepstream/ui/PropertyList';
import { Datetime } from '@deepstream/ui/Datetime';
import { isUserSuspended } from '@deepstream/common/user-utils';
import { callAll } from '@deepstream/utils/callAll';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { ModalProps, Modal, ModalHeader, ModalBody, ModalFooter, SaveButton, CancelButton } from '@deepstream/ui-kit/elements/popup/Modal';
import { SwitchField } from '@deepstream/ui/form/SwitchField';
import { useToaster } from '@deepstream/ui/toast';
import { useMutation } from '@deepstream/ui/useMutation';
import { Company } from '@deepstream/ui/ui/types';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { Id } from './Id';
import { UserSuspensionModal } from './UserSuspensionModal';

export const getStatus = (user: any) =>
  user.pendingCreation ? 'Pending' : user.status ? capitalize(user.status) : 'Active';

export const UseMfaAuthModal = ({
  user,
  onCancel,
  onSave,
  ...props
}: { user: any; onCancel: any; onSave: any } & ModalProps) => {
  const adminApi = useAdminApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();

  const [updateUserStatus] = useMutation(adminApi.updateUserMfaAuth, {
    onSuccess: () => toaster.success('Status updated successfully'),
    onError: () =>
      toaster.error('An error occurred when trying to update status'),
    onSettled: () =>
      queryClient.invalidateQueries(['user', { userId: user._id }]),
  });

  return (
    <Modal {...props}>
      <Formik
        initialValues={{
          mfaAuthEnabled: user.mfaAuthEnabled,
        }}
        validationSchema={yup.object().shape({
          mfaAuthEnabled: yup.boolean(),
        })}
        onSubmit={async ({ mfaAuthEnabled }, { setSubmitting }) => {
          await updateUserStatus(
            {
              userId: user._id,
              mfaAuthEnabled,
            },
            {
              onSuccess: onSave,
              onSettled: () => setSubmitting(false),
            },
          );
        }}
      >
        {({ isSubmitting, dirty }) => (
          <Form>
            <ModalHeader onClose={onCancel}>Edit 2FA status</ModalHeader>
            <ModalBody>
              <Stack gap={3}>
                <SwitchField
                  label="2FA status"
                  name="mfaAuthEnabled"
                  aria-label="2FA status"
                  checkedIcon={false}
                  uncheckedIcon={false}
                  width={42}
                  checkedText="Enabled"
                  uncheckedText="Disabled"
                />
              </Stack>
            </ModalBody>
            <ModalFooter>
              <CancelButton onClick={onCancel} />
              <SaveButton disabled={isSubmitting || !dirty} />
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

type UserGeneralPropertyListProps = { user: any };

export const UserGeneralPropertyList = ({ user } : UserGeneralPropertyListProps) => {
  const userSuspensionModal = useModalState();
  const userMfaAuthModal = useModalState();
  const adminApi = useAdminApi();
  const toaster = useToaster();
  const queryClient = useQueryClient();

  const { data: companies } = useQuery(
    ['companies', { userId: user._id }],
    wrap(adminApi.getUserCompanies),
  );

  const { data: lastVerificationEmail } = useQuery(
    ['verificationEmailSentDate', { userId: user._id, emailType: 'verifyEmail' }],
    wrap(adminApi.getLastEmailByType),
  );

  const [resendVerificationEmail, { status: verificationEmailStatus }] = useMutation(
    adminApi.resendVerificationEmail, {
      onSuccess: callAll(
        () => toaster.success('Verification email resent'),
        () => {
          queryClient.invalidateQueries(['verificationEmailSentDate', { userId: user._id }]);
          queryClient.invalidateQueries(['emails', { userId: user._id }]);
        },
      ),
      onError: callAll(
        () => toaster.error('Verification email could not be resent'),
      ),
    });

  const generalProperties = useMemo(
    () => {
      if (!user) return [];

      const properties = [
        { name: 'Account status', value: user.pendingCreation ? 'Not signed up' : 'Signed up' },
        { name: 'Verification status', value: user.verified ? 'Verified' : 'Unverified' },
        { name: 'Suspended?', value: isUserSuspended(user) ? 'Yes' : 'No' },
        { name: '2FA status?', value: user.mfaAuthEnabled ? 'Enabled' : 'Not Enabled' },
        { name: 'ID', value: user._id, Component: Id },
        { name: 'Created on', value: get(user, 'createdAt'), Component: Datetime },
        { name: 'Last seen', value: get(user, 'lastSeen'), Component: Datetime },
      ];

      if (isUserSuspended(user)) {
        const suspensionType = {
          name: 'Suspension type',
          value: user.suspendedUntil ? 'Incorrect password' : 'Admin initiated',
        };
        const suspensionExpires = {
          name: 'Suspension expires',
          value: user.suspendedUntil,
          Component: Datetime,
        };
        properties.splice(3, 0, suspensionType);
        properties.splice(4, 0, suspensionExpires);
      }

      if (!user.pendingCreation && !user.verified) {
        const verificationLastSent = {
          name: 'Verification last sent',
          value: get(lastVerificationEmail, 'date'),
          Component: Datetime,
        };
        properties.splice(2, 0, verificationLastSent);
      }

      return properties;
    },
    [user, lastVerificationEmail],
  );

  const canResendVerificationEmail = !user.pendingCreation && !user.verified;

  /*
    The button should be ENABLED only where BOTH of the following conditions are true
    - User belongs to NO companies with 2FA enabled
    - User 2FA status is Enabled
  */
  const isEditMfaDisabled = useMemo(() => {
    const hasUserMfaEnabled = user.mfaAuthEnabled;
    const hasAnyCompanyMfaEnabled = companies?.some((company : Company) => company.featureFlags?.mfaAuthEnabled);

    return hasAnyCompanyMfaEnabled || !hasUserMfaEnabled;
  }, [user, companies]);

  return (
    <>
      <PropertyList properties={generalProperties}>
        <Skip />
        <Skip />
        {canResendVerificationEmail ? (
          <PropertyListAction
            label="Resend"
            icon="paper-plane"
            onClick={() => {
              resendVerificationEmail(user._id);
            }}
            disabled={verificationEmailStatus === 'loading'}
          />
        ) : (
          null
        )}
        <PropertyListAction label="Edit" icon="pencil-alt" onClick={userSuspensionModal.open} />
        <Tooltip content={isEditMfaDisabled ? '2FA can only be edited when the following are both true – 2FA is enabled and the user does not belong to any company with 2FA enabled.' : ''}>
          <div style={{ display: 'inline-block' }}>
            <PropertyListAction label="Edit" icon="pencil-alt" onClick={userMfaAuthModal.open} disabled={isEditMfaDisabled} />
          </div>
        </Tooltip>
      </PropertyList>
      <UserSuspensionModal
        onSave={userSuspensionModal.close}
        onCancel={userSuspensionModal.close}
        isOpen={userSuspensionModal.isOpen}
        onRequestClose={userSuspensionModal.close}
        user={user}
      />
      <UseMfaAuthModal
        onSave={userMfaAuthModal.close}
        onCancel={userMfaAuthModal.close}
        isOpen={userMfaAuthModal.isOpen}
        onRequestClose={userMfaAuthModal.close}
        user={user}
      />
    </>
  );
};
