import { Contract, ContractOverview, ContractReporting, ContractRoles } from '@deepstream/common/contract';
import { MembershipDecision, RoleRestrictionProfile } from '@deepstream/common/user-utils';
import { Questionnaire, QuestionnaireOverview, QuestionnaireRoles, QuestionnaireTemplate, QuestionnaireTemplateOverview } from '@deepstream/common/preQual';

import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';
import { identity, isNil, isUndefined, omitBy, pickBy } from 'lodash';

import { RequestsReportingConfig } from '@deepstream/common/reporting';
import { Attachment, CompanyMinimized } from '@deepstream/common/rfq-utils';
import { TermsOfService } from '@deepstream/common';
import {
  AdminConfig, AdminRequestMeta, ClaimWithCompanyAndUser, CompanyAddress, CompanyRoles,
  ExchangeSnapshot, RfxStructure, User, IntegrationDataType, Resource, DashboardTemplate,
} from './types';
import { CompanyFeatureFlags } from './ui/types';

export const cacheTypes = {
  rfxTemplateActorCache: 'rfxTemplateActorCache',
  rfxRequestActorCache: 'rfxRequestActorCache',
  contractActorCache: 'contractActorCache',
  contractTemplateActorCache: 'contractTemplateActorCache',
  questionnaireTemplateActorCache: 'questionnaireTemplateActorCache',
  questionnaireActorCache: 'questionnaireActorCache',
  supplierListActorCache: 'supplierListActorCache',
} as const;

export type CacheType = typeof cacheTypes[keyof typeof cacheTypes];

export class AdminApiClient {
  instance: AxiosInstance;

  tokenGenerator: any;

  constructor(baseURL: string) {
    this.instance = axios.create({
      baseURL,
      withCredentials: true,
      withXSRFToken: (config) => Boolean(config.withCredentials),
    });

    this.instance.interceptors.request.use(
      async config => {
        if (!config.withCredentials) {
          return config;
        }

        let token;

        try {
          token = await this.getToken();
        } catch (error) {
          console.error('Could not get token', error);
        }

        return token
          ? {
            ...config,
            headers: { ...config.headers, Authorization: `Bearer ${token}` } as AxiosRequestHeaders,
          }
          : config;
      },
      error => {
        Promise.reject(error);
      },
    );

    this.instance.interceptors.response.use(response => response.data);
  }

  setTokenGenerator(tokenGenerator) {
    this.tokenGenerator = tokenGenerator;
  }

  getToken() {
    return this.tokenGenerator
      ? this.tokenGenerator()
      : undefined;
  }

  getResourceById = ({ resourceId }: { resourceId: string }) =>
    this.instance.get(`/admin/resource/${String(resourceId).trim()}/type`);

  createCompany = ({ name, type }: { name: string; type: string }) =>
    this.instance.post('/admin/company', { name, group: type });

  getSubscription = ({ subscriptionUuid }: { subscriptionUuid: string }) =>
    this.instance.get(`/admin/subscription/${String(subscriptionUuid).trim()}`);

  requestSubscriptionUsageUpdate = ({ subscriptionUuid }: { subscriptionUuid: string }) =>
    this.instance.post(`/admin/subscription/${String(subscriptionUuid).trim()}/updates`);

  getCompany = ({ companyId }: { companyId: string }) =>
    this.instance.get(`/admin/company/${String(companyId).trim()}`);

  getRecentlyCreatedCompanies = ({ limit }: { limit?: string }) =>
    this.instance.get(`/admin/companies?limit=${String(limit)}`);

  getCompanyName = ({ companyId }: { companyId: string }) =>
    this.instance.get<string>(`/admin/company/${String(companyId).trim()}/name`);

  getCompanyUsers = ({ companyId, role }: { companyId: string; role?: string }) =>
    this.instance.get<User[]>(`/admin/company/${String(companyId).trim()}/users${role ? `?role=${role}` : ''}`);

  getCompanyRequests = ({ companyId }: { companyId: string }) =>
    this.instance.get(`/admin/company/${String(companyId).trim()}/requests`);

  getCompanyContracts = ({ companyId }: { companyId: string }) =>
    this.instance.get(`/admin/company/${String(companyId).trim()}/contracts`);

  getCompanyTemplates = ({ companyId }: { companyId: string }) =>
    this.instance.get(`/admin/company/${String(companyId).trim()}/templates`);

  getCompanyMembershipRequests = ({ companyId }: { companyId: string }) =>
    this.instance.get<User[]>(`/ajax/company/${companyId}/users/pending`);

  getCompanyUser = ({ companyId, userId }: { companyId: string; userId: string }) =>
    this.instance.get(`/admin/company/${String(companyId).trim()}/user/${userId}`);

  companyIdExists = ({ companyId }: { companyId: string }) =>
    this.instance.get(`/admin/company/${String(companyId).trim()}/exists/id`);

  companyNameExists = ({ name }: { name: string }) =>
    this.instance.get(`/admin/company/${name.trim()}/exists/name`);

  getUser = ({ userId }: { userId: string }) =>
    this.instance.get(`/admin/user/${userId}`);

  getRecentlyActiveUsers = ({ limit }: { limit?: number }) =>
    this.instance.get(`/admin/users?limit=${limit}`);

  createUser = ({ email, firstName, lastName }: { email: string; firstName: string; lastName: string }) =>
    this.instance.post('/admin/user', { email, firstName, lastName });

  getUserCompanies = ({ userId }: { userId: string }) =>
    this.instance.get(`/admin/user/${userId}/companies`);

  getUserQuestionnaires = ({ userId }: { userId: string }) =>
    this.instance.get(`/admin/user/${userId}/questionnaires`);

  getUserRequests = ({ userId }: { userId: string }) =>
    this.instance.get(`/admin/user/${userId}/requests`);

  userExists = ({ email }: { email: string }) =>
    this.instance.get(`/admin/user/${encodeURIComponent(email)}/exists`);

  getUserEmails = ({ userId }: { userId: string }) =>
    this.instance.get(`/admin/user/${userId}/receivedEmails`);

  getUserAccessEvents = ({ userId }: { userId: string }) =>
    this.instance.get(`/admin/user/${userId}/accessEvents`);

  getAdminRequestOverview = ({ requestId }: { requestId: string }) =>
    this.instance.get<{
      meta: AdminRequestMeta;
      structure: RfxStructure;
      exchanges: ExchangeSnapshot[];
    }>(`/admin/request/hydrated/${requestId}`);

  getAdminTemplateOverview = ({ templateId }: { templateId: string }) =>
    this.instance.get<{
      template: DashboardTemplate;
      structure: RfxStructure;
      requestsCount: number;
    }>(`/admin/template/hydrated/${templateId}`);

  getTemplateRequests = ({ templateId }: { templateId: string }) =>
    this.instance.get(`/admin/template/${String(templateId).trim()}/requests`);

  deleteRequest = ({ requestId }: { requestId: string }) =>
    this.instance.delete(`/admin/request/${requestId}`);

  deleteTemplate = ({ templateId }: { templateId: string }) =>
    this.instance.delete(`/admin/template/${templateId}`);

  getCompanyUserQuestionnaires = ({ companyId, userId }: { companyId: string; userId: string }) =>
    this.instance.get(`/admin/company/${companyId}/user/${userId}/questionnaires`);

  getCompanyUserRequests = ({ companyId, userId }: { companyId: string; userId: string }) =>
    this.instance.get(`/admin/company/${companyId}/user/${userId}/requests`);

  getCompanyUserContracts = ({ companyId, userId }: { companyId: string; userId: string }) =>
    this.instance.get(`/admin/company/${companyId}/user/${userId}/contracts`);

  getPendingCompanyClaims = () =>
    this.instance.get<ClaimWithCompanyAndUser[]>('/admin/companies/claims/pending');

  getRecentlyCreatedRequests = ({ limit }: { limit?: number }) =>
    this.instance.get(`/admin/requests?limit=${limit}`);

  removeUserFromCompany = ({
    companyId,
    userId,
    questionnaireReplacementUserId,
    rfxReplacementUserId,
    contractReplacementUserId,
  }: {
    companyId: string;
    userId: string;
    questionnaireReplacementUserId?: string;
    rfxReplacementUserId?: string;
    contractReplacementUserId?: string;
  }) => {
    const queryParams = new URLSearchParams(omitBy(
      { questionnaireReplacementUserId, rfxReplacementUserId, contractReplacementUserId } as Record<string, string>,
      isNil,
    ));

    return this.instance.delete(`/admin/company/${companyId}/user/${userId}?${queryParams.toString()}`);
  };

  addTeamMember = ({
    companyId,
    user,
  }: {
    companyId: string;
    user: {
      email: string;
      roleRestrictionProfile: RoleRestrictionProfile;
      roles: CompanyRoles;
    };
  }) => this.instance.put(`/admin/company/${companyId}/user`, user);

  applyMembershipDecision = ({
    companyId,
    userId,
    decision,
    roleRestrictionProfile,
    roles,
  }: {
    companyId: string;
    userId: string;
    decision: MembershipDecision;
    roleRestrictionProfile?: RoleRestrictionProfile;
    roles?: CompanyRoles;
  }) => this.instance.put(
    `/admin/company/${companyId}/user/${userId}/membership/${decision}`,
    omitBy({ roleRestrictionProfile, roles }, isUndefined),
  );

  updateCompanySubscription = ({
    companyId,
    isPaying,
    recurlySubscriptionUuid,
  }: {
    companyId: string;
    isPaying: boolean;
    recurlySubscriptionUuid?: string;
  }) =>
    this.instance.put(
      `/admin/company/${companyId}/subscription`,
      {
        subscription: isPaying ? { recurlySubscriptionUuid } : null,
      },
    );

  updateSearchVisibility = ({ companyId, isHidden }: { companyId: string; isHidden: boolean }) =>
    this.instance.put(`/admin/company/${companyId}/hidden`, { hidden: isHidden });

  updateReferenceNumberOffset = ({ companyId, referenceStartNumber }: { companyId: string; referenceStartNumber: number }) =>
    this.instance.put(`/admin/company/${companyId}/referenceNumberOffset`, { referenceStartNumber });

  updateCompanyType = ({ companyId, type }: { companyId: string; type: string }) =>
    this.instance.put(`/admin/company/${companyId}/group`, { group: type });

  updateCompanyAddress = ({ companyId, ...address }: { companyId: string } & CompanyAddress) =>
    this.instance.put(`/admin/company/${companyId}/address`, { address });

  updateCompanyRoles = ({
    userId,
    companyId,
    roleRestrictionProfile,
    roles,
    questionnaireReplacementUserId,
    rfxReplacementUserId,
    contractReplacementUserId,
  }: {
    userId: string;
    companyId: string;
    roleRestrictionProfile: RoleRestrictionProfile;
    roles: CompanyRoles;
    questionnaireReplacementUserId?: string;
    rfxReplacementUserId?: string;
    contractReplacementUserId?: string;
  }) =>
    this.instance.put(`/admin/user/${userId}/roles`, {
      companyId,
      roleRestrictionProfile,
      roles,
      ...(pickBy({ questionnaireReplacementUserId, rfxReplacementUserId, contractReplacementUserId }, identity)),
    });

  updateJobTitle = ({ userId, companyId, jobTitle }: { userId: string; companyId: string; jobTitle: string }) =>
    this.instance.put(`/admin/user/${userId}/jobTitle`, {
      companyId,
      jobTitle,
    });

  updateUserEmail = ({ userId, email }: { userId: string; email: string }) =>
    this.instance.put(`/admin/user/${userId}/email`, {
      email,
    });

  updateUserName = ({ userId, firstName, lastName }: { userId: string; firstName: string; lastName: string }) =>
    this.instance.put(`/admin/user/${userId}/name`, {
      firstName,
      lastName,
    });

  updateUserLocale = ({ userId, locale }: { userId: string; locale: string }) =>
    this.instance.put(`/admin/user/${userId}/locale`, {
      locale,
    });

  updateUserPhoneNumber = ({ userId, phoneNumber }: { userId: string; phoneNumber: string }) =>
    this.instance.put(`/admin/user/${userId}/phoneNumber`, {
      phoneNumber,
    });

  updateRfq = ({ companyId, requestId, changes }: { companyId: string; requestId: string; changes: any[] }) =>
    this.instance.put(`/admin/company/${companyId}/rfq/${requestId}`, { changes });

  updateUserStatus = ({ userId, status }: { userId: string; status: string }) =>
    this.instance.put(`/admin/user/${userId}/status`, { status });

  updateUserMfaAuth = ({ userId, mfaAuthEnabled }: { userId: string; mfaAuthEnabled: boolean }) =>
    this.instance.put(`/admin/user/${userId}/mfaAuth`, { mfaAuthEnabled });

  updateRequestRecipients = ({ buyerId, rfqId, recipientIds }: { buyerId: string; rfqId: string; recipientIds: string[] }) =>
    this.instance.put(`/admin/company/${buyerId}/rfq/${rfqId}/recipients`, { recipientIds });

  refreshCompaniesIndex = () =>
    this.instance.post('/admin/search-indexes/companies');

  refreshProductsIndex = () =>
    this.instance.post('/admin/search-indexes/products');

  refreshRfqDashboardOverviewsIndex = () =>
    this.instance.post('/admin/search-indexes/rfqDashboardOverviews');

  refreshContractOverviewsIndex = () =>
    this.instance.post('/admin/search-indexes/contractOverviews');

  getLastEmailByType = ({ userId, emailType }: { userId: string; emailType: string }) =>
    this.instance.get(`/admin/user/${userId}/emails/${emailType}/latest`);

  resendVerificationEmail = (userId: string) =>
    this.instance.post(`/admin/user/${userId}/emails/verifyEmail`, {});

  getDiscoveryTransaction = ({ transactionId }) =>
    this.instance.get(`/admin/discovery-logs/transaction/${transactionId}`);

  getDiscoveryQueries = () =>
    this.instance.get('/admin/discovery-logs/queries');

  getProductDashboard = () =>
    this.instance.get('/admin/productDashboard');

  getReports = () =>
    this.instance.get('/admin/reports');

  getReport = ({ reportId }: { reportId: string }) =>
    this.instance.get(`/admin/report/${reportId}`);

  getReportDownloadUrl = ({ reportId }: { reportId: string }) =>
    this.instance.get(`/admin/report/${reportId}/download`);

  requestReport = ({ reportType }: { reportType: string }) =>
    this.instance.post(`/admin/reports/${reportType}`);

  getConfig = () => this.instance.get<AdminConfig>('/admin/config');

  updateConfig = (config: Partial<AdminConfig>) =>
    this.instance.put('/admin/config', config);

  updateCompanyFeatureFlags = ({
    companyId,
    featureFlags,
  }: { companyId: string; featureFlags: CompanyFeatureFlags }) =>
    this.instance.put(`/admin/company/${companyId}/featureFlags`, featureFlags);

  updateRequestCache = ({ requestId, fromScratch }: { requestId: string, fromScratch?: boolean }) =>
    this.instance.post(`/admin/rfq/${requestId}/cache`, { fromScratch });

  updateTemplateCache = ({ templateId, fromScratch }: { templateId: string, fromScratch?: boolean }) =>
    this.instance.post(`/admin/template/${templateId}/cache`, { fromScratch });

  recalculateDerivedRfqData = () =>
    this.instance.post('/admin/recalculateRfqDerivedFields');

  recalculateDerivedContractData = () =>
    this.instance.post('/admin/recalculateDerivedContractData');

  recalculateDerivedQuestionnaireData = () =>
    this.instance.post('/admin/recalculateDerivedQuestionnaireData');

  recalculateDerivedQuestionnaireTemplateData = () =>
    this.instance.post('/admin/recalculateDerivedQuestionnaireTemplateData');

  recalculateDerivedSupplierListData = () =>
    this.instance.post('/admin/recalculateDerivedSupplierListData');

  sendScheduledEmails = () =>
    this.instance.get('/admin/sendScheduledEmails');

  repopulateCaches = ({ cacheTypes, dryRun }: { cacheTypes: CacheType[]; dryRun: boolean }) =>
    this.instance.post('/admin/repopulate-caches', { cacheTypes, dryRun });

  clearCache = ({
    cacheType,
  }: {
    cacheType: CacheType;
  }) =>
    this.instance.delete(`/admin/caches/${cacheType}`);

  getIntegrationData = ({ rfxId, type }: { rfxId: string, type: IntegrationDataType }) =>
    this.instance.get(`/admin/integrationData/${rfxId}?type=${type}`);

  getIntegrationDataByCompany = ({ companyId, type }: { companyId: string, type: IntegrationDataType }) =>
   this.instance.get(`/admin/company/integrationData/${companyId}?type=${type}`);

  copyCompanyResources = ({
    sourceCompanyId,
    targetCompanyId,
    resources,
  }: {
    sourceCompanyId: string;
    targetCompanyId: string;
    resources: Resource[];
  }) =>
    this.instance.post(`/admin/company/${targetCompanyId}/copy/source/${sourceCompanyId}`, { resources });

  getRecentAuctions = ({ limit }: { limit: number }) => this.instance.get(`/admin/auctions?limit=${limit}`);

  cancelAuction = ({ rfqId, auctionId }: { rfqId: string; auctionId: string }) =>
    this.instance.put(`/admin/request/${rfqId}/auction/${auctionId}/cancel`);

  pauseAuction = ({ rfqId, auctionId }: { rfqId: string; auctionId: string }) =>
    this.instance.put(`/admin/request/${rfqId}/auction/${auctionId}/pause`);

  resumeAuction = ({ rfqId, auctionId }: { rfqId: string; auctionId: string }) =>
    this.instance.put(`/admin/request/${rfqId}/auction/${auctionId}/resume`);

  requestAuctionLotBidRetraction = ({ rfqId, auctionId, lotId, bidderId, price }: {
    rfqId: string;
    auctionId: string;
    lotId: string;
    bidderId: string;
    price: number;
  }) =>
    this.instance.put(`/admin/request/${rfqId}/auction/${auctionId}/retract`, { lotId, bidderId, price });

  extendAuctionBidding = ({ rfqId, auctionId, extensionSec }: { rfqId: string; auctionId: string; extensionSec: number }) =>
    this.instance.put(`/admin/request/${rfqId}/auction/${auctionId}/extend`, { extensionSec });

  updateRequestSizeLimits = ({ rfqId, maxComplexity, maxExchangeDefCount }: { rfqId: string; maxComplexity?: number | null; maxExchangeDefCount?: number | null }) =>
    this.instance.put(`/admin/request/${rfqId}/sizeLimits`, { maxComplexity, maxExchangeDefCount });

  getRecentlyCreatedContracts = ({ limit }: { limit?: number }) =>
    this.instance.get<ContractOverview[]>(`/admin/contracts?limit=${limit}`);

  getRecentlyCreatedTemplates = ({ limit }: { limit?: number }) =>
    this.instance.get(`/admin/template?limit=${limit}`);

  getRecentlyCreatedQuestionnaireTemplatesOverview = ({ limit }: { limit?: number }) =>
    this.instance.get<{
      templates: QuestionnaireTemplateOverview[];
      companyById: Record<string, CompanyMinimized>
    }>(`/admin/questionnaireTemplates?limit=${limit}`);

  getCompanyQuestionnaireTemplates = ({ companyId }: { companyId: string }) =>
    this.instance.get<QuestionnaireTemplateOverview[]>(`/admin/company/${companyId}/questionnaireTemplates`);

  getCompanyReceivedQuestionnaires = ({ companyId }: { companyId: string }) =>
    this.instance.get<QuestionnaireOverview[]>(`/admin/company/${companyId}/questionnaires/received`);

  getTemplate = ({ templateId }: { templateId: string }) =>
    this.instance.get(`/admin/template/${templateId}`);

  getAdminQuestionnaireTemplateOverview = ({ templateId }: { templateId: string }) =>
    this.instance.get<{
      template: QuestionnaireTemplate;
      companyName: string;
      sentQuestionnaires: QuestionnaireOverview[];
    }>(`/admin/questionnaireTemplate/${templateId}`);

  getAdminQuestionnaire = ({ questionnaireId }: { questionnaireId: string }) =>
    this.instance.get<Questionnaire>(`/admin/questionnaire/${questionnaireId}`);

  addQuestionnaireTeamMembers = ({
    questionnaireId,
    users,
    companyId,
  }: {
    questionnaireId: string;
    users: (Pick<User, '_id' | 'name'> & { isOwner?: boolean; roles?: QuestionnaireRoles })[];
    companyId: string;
  }) =>
    this.instance.put(
      `/admin/questionnaire/${questionnaireId}/add-team-members`,
      { users, companyId },
    );

  removeQuestionnaireTeamMember = ({
    questionnaireId,
    companyId,
    userId,
  }: {
    questionnaireId: string;
    companyId: string;
    userId: string;
  }) =>
    this.instance.put(
      `/admin/questionnaire/${questionnaireId}/remove-team-member`,
      { userId, companyId },
    );

  updateQuestionnaireTeamMemberRoles = ({
    questionnaireId,
    companyId,
    userId,
    isOwner,
    roles,
  }: {
    questionnaireId: string;
    companyId: string;
    userId: string;
    isOwner?: boolean;
    roles?: QuestionnaireRoles;
  }) => {
    return this.instance.put(
      `/admin/questionnaire/${questionnaireId}/update-roles`,
      { userId, isOwner, roles, companyId },
    );
  };

  getAdminContract = ({ contractId }: { contractId: string }) =>
    this.instance.get<Contract>(`/admin/contract/${contractId}`);

  addContractTeamMember = ({
    contractId,
    companyId,
    user,
  }: {
    contractId: string;
    companyId: string;
    user: Pick<User, '_id' | 'email' | 'name'> & { isOwner?: boolean; contractRoles?: ContractRoles };
  }) =>
    this.instance.put(
      `/admin/contract/${contractId}/add-team-member`,
      { user, companyId },
    );

  updateContractTeamMemberRoles = ({
    contractId,
    companyId,
    userId,
    isOwner,
    contractRoles,
  }: {
    contractId: string;
    companyId: string;
    userId: string;
    isOwner?: boolean;
    contractRoles?: ContractRoles;
  }) =>
    this.instance.put(
      `/admin/contract/${contractId}/update-roles`,
      { userId, companyId, isOwner, contractRoles },
    );

  removeContractTeamMember = ({
    contractId,
    companyId,
    userId,
  }: {
    contractId: string;
    companyId: string;
    userId: string;
  }) =>
    this.instance.put(
      `/admin/contract/${contractId}/remove-team-member`,
      { userId, companyId },
    );

  deleteContractCache = ({ contractId }: { contractId: string }) =>
    this.instance.delete(`/admin/contract/${contractId}/cache`);

  deleteContract = ({ contractId }: { contractId: string }) =>
    this.instance.delete(`/admin/contract/${contractId}`);

  getUserContracts = ({ userId }: { userId: string }) =>
    this.instance.get<ContractOverview[]>(`/admin/user/${userId}/contracts`);

  getLanguages = () =>
    this.instance.get('/admin/languages');

  addLanguage = ({ code, isEnabled }: { code: string, isEnabled: boolean }) =>
    this.instance.post('/admin/language', { code, isEnabled });

  getEnabledLanguages = () =>
    this.instance.get('/ajax/admin/enabledLanguages');

  addExternalSystem = ({
    systemId,
    companyId,
    name,
    description,
    hasExternalToInternalCompanyMap,
    allowManyToOneMapping,
    hasSupplierStatuses,
    hasSupplierBasicInfo,
  }: {
    systemId: string,
    companyId: string,
    name: string,
    description: string,
    hasExternalToInternalCompanyMap: string,
    allowManyToOneMapping: boolean,
    hasSupplierStatuses: boolean,
    hasSupplierBasicInfo: boolean,
  }) =>
    this.instance.post('/admin/externalSystem', {
      systemId,
      companyId,
      name,
      description,
      hasExternalToInternalCompanyMap,
      allowManyToOneMapping,
      hasSupplierStatuses,
      hasSupplierBasicInfo,
    });

  deleteExternalSystem = ({ externalSystemId }: { externalSystemId: string }) =>
    this.instance.delete(`/admin/externalSystem/${externalSystemId}`);

  updateExternalSystem = ({
    _id: externalSystemId,
    name,
    description,
    hasExternalToInternalCompanyMap,
    allowManyToOneMapping,
    hasSupplierStatuses,
    hasSupplierBasicInfo,
  }: {
    _id: string,
    name: string,
    description: string
    hasExternalToInternalCompanyMap: string,
    allowManyToOneMapping: boolean,
    hasSupplierStatuses: boolean,
    hasSupplierBasicInfo: boolean,
  }) =>
    this.instance.put(`/admin/externalSystem/${externalSystemId}`, {
      name,
      description,
      hasExternalToInternalCompanyMap,
      allowManyToOneMapping,
      hasSupplierStatuses,
      hasSupplierBasicInfo,
    });

  getExternalSystem = ({ externalSystemId }: { externalSystemId: string }) =>
    this.instance.get(`/admin/externalSystem/${externalSystemId}`);

  getExternalSystems = ({ companyId }: { companyId: string }) =>
    this.instance.get(`/admin/company/${companyId}/externalSystems`);

  addSubscription = ({
    externalSystemId,
    description,
    systemEventType,
    webhookUrl,
    webhookApiKey,
    receiveNotifications,
  }: {
    externalSystemId: string,
    description: string,
    systemEventType: string,
    webhookUrl: string,
    webhookApiKey: string,
    receiveNotifications: boolean,
  }) =>
    this.instance.post(`/admin/externalSystem/${externalSystemId}/subscription`, {
      description,
      systemEventType,
      webhookUrl,
      webhookApiKey,
      receiveNotifications,
    });

  deleteSubscription = ({ _id, externalSystemId }: { _id: string, externalSystemId: string }) =>
    this.instance.delete(`/admin/externalSystem/${externalSystemId}/subscription/${_id}`);

  updateSubscription = ({
    _id,
    externalSystemId,
    description,
    systemEventType,
    webhookUrl,
    webhookApiKey,
    receiveNotifications,
  }: {
    _id: string,
    externalSystemId: string,
    description: string,
    systemEventType: string,
    webhookUrl: string,
    webhookApiKey: string,
    receiveNotifications: boolean,
  }) =>
    this.instance.put(`/admin/externalSystem/${externalSystemId}/subscription/${_id}`, {
      systemEventType,
      description,
      webhookUrl,
      webhookApiKey,
      receiveNotifications,
    });

  getChatbotChats = () =>
    this.instance.get('/admin/chatbot/chats');

  getChatbotChat = ({ chatId }: { chatId: string }) =>
    this.instance.get(`/admin/chatbot/chat/${chatId}`);

  getSubscriptionEventTypes = () =>
    this.instance.get('/admin/subscriptionEventTypes');

  getIntegrationDataBySystemAndCompany = ({ systemId, companyId }: { systemId: string, companyId: string }) =>
    this.instance.get(`/admin/company/${companyId}/integrationData/system/${systemId}`);

  getRequestsReportingCardsData = ({
    companyId,
    section,
    config,
    startDate,
    endDate,
    selectedCompanyIds,
  }: {
    companyId: string;
    section: 'budget' | 'value' | 'savings';
    config: RequestsReportingConfig;
    startDate: Date;
    endDate: Date;
    selectedCompanyIds: string[];
  }) => {
    const queryParams = new URLSearchParams({
      ...config,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      selectedCompanyIds: JSON.stringify(selectedCompanyIds),
    });

    return this.instance.get(`/admin/company/${companyId}/reporting/requests/${section}/cards?${queryParams.toString()}`);
  };

  getRequestsReportingTableData = ({
    companyId,
    section,
    selectedCompanyIds,
  }: {
    companyId: string;
    section: 'budget' | 'value' | 'savings';
    selectedCompanyIds: string[];
  }) => {
    const queryParams = new URLSearchParams({
      selectedCompanyIds: JSON.stringify(selectedCompanyIds),
    });

    return this.instance.get(`/admin/company/${companyId}/reporting/requests/${section}/table?${queryParams.toString()}`);
  };

  getRequestsReportingOtherData = ({
    companyId,
    currency,
    selectedCompanyIds,
  }: {
    companyId: string;
    currency: string;
    selectedCompanyIds: string[];
  }) => {
    const queryParams = new URLSearchParams({
      currency,
      selectedCompanyIds: JSON.stringify(selectedCompanyIds),
    });

    return this.instance.get(`/admin/company/${companyId}/reporting/requests?${queryParams.toString()}`);
  };

  getContractsReportingData = ({
    companyId,
    currency,
    selectedCompanyIds,
  }: {
    companyId: string;
    currency: string;
    selectedCompanyIds: string[];
  }) => {
    const queryParams = new URLSearchParams({
      currency,
      selectedCompanyIds: JSON.stringify(selectedCompanyIds),
    });

    return this.instance.get<ContractReporting>(`/admin/company/${companyId}/reporting/contracts?${queryParams.toString()}`);
  };

  getCompaniesWithActiveIntegrations = () =>
    this.instance.get('/admin/companies/integrations/active');

  getIntegrations = ({
    type,
  }: {
    type: string
  }) =>
    this.instance.get(`/admin/integrations?type=${type}`);

  getTermsOfService = () => this.instance.get<TermsOfService[]>('/admin/termsOfService');

  getTermsAttachmentDownloadUrl = (attachment: Attachment) =>
    this.instance.post<{ url: string }>(`/admin/termsOfService/downloadUrl/${attachment._id}`);

  getTermsAttachmentPreview = ({ attachmentId }: { attachmentId: string }) =>
    this.instance.get<{ html: string }>(`/admin/termsOfService/preview/${attachmentId}`);

  createTermsOfService = ({
    attachments,
    changesDescription,
  }: {
    attachments: Attachment[];
    changesDescription: string;
  }) =>
    this.instance.post<boolean>('/admin/termsOfService', { attachments, changesDescription });

  updateTermsOfService = ({
    termsId,
    updates,
  }: {
    termsId: string;
    updates: {
      attachments: Attachment[];
      changesDescription: string;
    };
  }) =>
    this.instance.put<boolean>(`/admin/termsOfService/${termsId}`, updates);

  makeTermsOfServiceLive = ({ termsId }: { termsId: string }) =>
    this.instance.put<boolean>(`/admin/termsOfService/${termsId}/live`);

  deleteTermsOfServiceLive = ({ termsId }: { termsId: string }) =>
    this.instance.delete<boolean>(`/admin/termsOfService/${termsId}`);

  getChildCompanies = ({
    companyId,
  }: {
    companyId: string;
  }) =>
    this.instance.get<{ _id: string; name: string; isPending?: boolean }[]>(`/admin/company/${companyId}/children`);

  setParentCompany = ({
    companyId,
    parentCompanyId,
  }: {
    companyId: string;
    parentCompanyId: string | null;
  }) =>
    this.instance.put<boolean>(`/admin/company/${companyId}/parent`, { parentCompanyId });
}
