import { DateComparisonFilter, FilterComparisonOperatorType, IconValue } from '@deepstream/common';
import { flatten, get, isEmpty, isNil, uniqBy } from 'lodash';
import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { endOfDay, startOfDay } from 'date-fns';
import { withProps } from '@deepstream/ui-utils';
import { DateFormat, localeFormatDate } from '@deepstream/utils';
import { CompanyMinimized, getRequestTagAncestorsNames, RequestTag, UserMinimized } from '@deepstream/common/rfq-utils';
import { AllRequestsGridColumnId } from '@deepstream/common/reporting';
import { PanelDivider } from '@deepstream/ui-kit/elements/Panel';
import { renderLabel, renderLabelWithIcon } from '../../RequestsTable';
import { MultiSelectFilterPage, OperatorAndDateFilterPage, OperatorAndNumberFilterPage } from '../../ui/ExchangeDefsGrid/GridFilterDropdown';
import { BooleanCell, BooleanOrDashCell, CompaniesWithLogoCell, CompanyLogoAndNameCell, CurrencyAmountOrDashCell, DateOrDashCell, NumberOrDashCell, OwnersCell, RequestStatusCell, TextOrDashCell } from '../../ui/ExchangeDefsGrid/nonValidationAwareValueCell';
import { useRequestStatusFilterItems } from '../Request/Sent/useRequestStatusFilterItems';
import { useCurrentUserLocale } from '../../useCurrentUser';
import { dateMatches, numberMatches, useBooleanFilterItems, useDateFilterItems, useNumberFilterItems, useRequestVisibilityFilterItems } from '../../filtering';
import { boolToLocaleString } from '../../utils';
import { useRequestTagsContext } from '../RequestTags/RequestTagsContext';
import { renderRequestTagLabel } from '../RequestTags/RequestTagLabel';

type Column = {
  _id: string;
  accessorKey: string;
  accessorFn: (request: any) => any;
  sortAccessorFn?: (request: any) => any;
  label: string;
  ValueCell: any;
  iconProps?: {
    icon?: string;
    isIconRegular?: boolean;
  };
  FilterPageComponent?: any;
  getFilterItems?: (requests: any[]) => any[];
  renderFilterItem?: (item: any) => React.ReactNode;
  matchesFilter?: (request: any, gridFilter: any) => boolean;
  getQueryParam?: (selectedItems: any[]) => any[];
  sortType?: string;
  width?: number;
};

type ColumnConfigById = Record<AllRequestsGridColumnId, Column>;

export const useAllRequestsReportingColumnConfigById = (): Record<AllRequestsGridColumnId, Column> => {
  const { t } = useTranslation(['translation', 'email']);
  const locale = useCurrentUserLocale();

  const requestStatusFilterItems = useRequestStatusFilterItems();
  const numberFilterItems = useNumberFilterItems();
  const dateFilterItems = useDateFilterItems();
  const booleanFilterItems = useBooleanFilterItems({
    withUppercaseLabel: true,
  });
  const requestVisibilityFilterItems = useRequestVisibilityFilterItems();

  const { tags, tagById } = useRequestTagsContext();
  const orderedTagsById = useMemo(() => {
      return tags.reduce((result, current, currentIndex) => {
        if (current._id) {
          result[current._id] = currentIndex;
        }
        return result;
      }, {});
    }, [tags],
  );

  const generateNumberColumnConfig = useCallback((columnId: string, label: string, icon?: string) => {
    return {
      _id: columnId,
      accessorKey: columnId,
      accessorFn: ({ request }) => get(request, columnId) ?? 0,
      sortAccessorFn: ({ request }) => get(request, columnId) ?? 0,
      label,
      ValueCell: NumberOrDashCell,
      iconProps: {
        icon: icon || '1' as IconValue,
      },
      FilterPageComponent: withProps(OperatorAndNumberFilterPage, {
        defaultValue: 0,
        minValue: 0,
        selectOperatorMinWidth: '180px',
      }),
      getFilterItems: () => numberFilterItems,
      renderFilterItem: renderLabel,
      matchesFilter: (request, gridFilter) => {
        const selectedItems = gridFilter.filters.data[gridFilter.pageId] || [];

        if (isEmpty(selectedItems)) {
          return true;
        } else {
          const { value, operator } = selectedItems[0] as { value: any; operator: any };
          const selectedCount = value;

          const count = get(request, columnId) ?? 0;

          return numberMatches(count, selectedCount, operator);
        }
      },
    };
  }, [numberFilterItems]);

  const generateDateColumnConfig = useCallback((
    columnId: string,
    label: string,
    iconProps?: { icon?: string, isIconRegular?: boolean },
  ) => {
    return {
      _id: columnId,
      accessorKey: columnId,
      accessorFn: ({ request }) => get(request, columnId),
      sortAccessorFn: ({ request }) => get(request, columnId),
      label,
      ValueCell: withProps(DateOrDashCell, { dateFormat: DateFormat.DD_MMM_YYYY_HH_MM_SS_ZZZ }) as any,
      iconProps: iconProps || {
        icon: 'calendar',
        isIconRegular: true,
      },
      FilterPageComponent: OperatorAndDateFilterPage,
      getFilterItems: () => dateFilterItems,
      renderFilterItem: renderLabel,
      matchesFilter: (request, gridFilter) => {
        const selectedItems = gridFilter.filters.data[gridFilter.pageId] || [];

        if (isEmpty(selectedItems)) {
          return true;
        } else {
          const { value, operator } = selectedItems[0] as { value: any; operator: any };
          const selectedDate = value ? new Date(value) : null;

          const dateStr = get(request, columnId);
          const date = dateStr ? new Date(dateStr) : null;

          return dateMatches(date, selectedDate, operator);
        }
      },
      getQueryParam: (selectedItems: { value: string; operator: FilterComparisonOperatorType }[]) => {
        const queryParam: DateComparisonFilter[] = [];
        const selected = selectedItems[0];

        if (selected && selected.value) {
          const { operator, value } = selected;
          const selectedDateStart = startOfDay(new Date(value)).getTime();
          const selectedDateEnd = endOfDay(new Date(value)).getTime();
          queryParam.push({
            operator,
            selectedDateStart,
            selectedDateEnd,
          });
        }

        return queryParam;
      },
    };
  }, [dateFilterItems]);

  const generateBooleanColumnConfig = useCallback((columnId: string, label: string, icon?: string, includeUndefined?: boolean) => {
    return {
      _id: columnId,
      accessorKey: columnId,
      accessorFn: ({ request }) => includeUndefined ? get(request, columnId) : Boolean(get(request, columnId)),
      sortAccessorFn: ({ request }) => Boolean(get(request, columnId)),
      label,
      ValueCell: withProps(includeUndefined ? BooleanOrDashCell : BooleanCell, { uppercase: true }),
      iconProps: {
        icon: icon || 'check' as IconValue,
      },
      FilterPageComponent: MultiSelectFilterPage,
      getFilterItems: () => includeUndefined ? [...booleanFilterItems, { label: '—', value: 'NULL' }] : booleanFilterItems,
      renderFilterItem: renderLabel,
      matchesFilter: (request, gridFilter) => {
        const selectedItems = gridFilter.filters.data[gridFilter.pageId] || [];

        if (isEmpty(selectedItems)) {
          return true;
        } else {
          const value = get(request, columnId);
          const localeString = (includeUndefined && isNil(value)) ? 'NULL' : boolToLocaleString({ value, locale, t, withUppercase: true });

          return selectedItems.some((item) => item === localeString);
        }
      },
      getQueryParam: (selectedItems: string[] = []) => {
        const trueString = boolToLocaleString({ value: true, locale, t, withUppercase: true });
        const falseString = boolToLocaleString({ value: false, locale, t, withUppercase: true });
        const localeBooleanMap = {
          [trueString]: true,
          [falseString]: false,
          '—': null,
        };
        return selectedItems.map((item) => localeBooleanMap[item]);
      },
    };
  }, [booleanFilterItems, locale, t]);

  const generateTextColumnConfig = useCallback((columnId: string, label: string, width?: number) => {
    return {
      _id: columnId,
      accessorKey: columnId,
      accessorFn: ({ request }) => {
        const value = get(request, columnId);
        if (!Array.isArray(value)) {
          return value;
        } else {
          return value.join(', ');
        }
      },
      sortAccessorFn: ({ request }) => {
        const value = get(request, columnId);
        if (!Array.isArray(value)) {
          return value;
        } else {
          return value.join(', ');
        }
      },
      sortType: 'caseInsensitive',
      label,
      ValueCell: TextOrDashCell,
      ...(width && { width }),
    };
  }, []);

  const generateCompaniesColumnConfig = useCallback((columnId: string, label: string) => {
    return {
      _id: columnId,
      accessorKey: columnId,
      accessorFn: ({ request }) => get(request, columnId) as CompanyMinimized[],
      sortAccessorFn: ({ request }) => {
        const companies = get(request, columnId) as CompanyMinimized[];
        return companies.map((company) => company.name).join('');
      },
      sortType: 'caseInsensitive',
      label,
      ValueCell: CompaniesWithLogoCell,
      iconProps: {
        icon: 'building' as IconValue,
        isIconRegular: true,
      },
      FilterPageComponent: MultiSelectFilterPage,
      getFilterItems: (requests) => {
        return uniqBy<{ label: string, value: string }>(
          flatten(
            requests.map((request) => {
              const companies = get(request, columnId) as CompanyMinimized[];
              return companies.map((company) => ({
                label: company.name,
                value: company._id,
              }));
            }),
          ),
          'value',
        ).sort((a, b) => a.label?.localeCompare(b.label, locale));
      },
      renderFilterItem: renderLabel,
      matchesFilter: (request, gridFilter) => {
        const selectedItems = gridFilter.filters.data[gridFilter.pageId] || [];

        if (isEmpty(selectedItems)) {
          return true;
        } else {
          const companies = get(request, columnId) as CompanyMinimized[];

          return companies.some((company) => selectedItems.some((item) => company._id === item));
        }
      },
    };
  }, [locale]);

  const generateUsersColumnConfig = useCallback((columnId: string, label: string) => {
    return {
      _id: columnId,
      accessorKey: columnId,
      accessorFn: ({ request }) => {
        let users = get(request, columnId);
        if (!Array.isArray(users)) {
          users = users ? [users] : [];
        }
        return users;
      },
      sortAccessorFn: ({ request }) => {
        let users = get(request, columnId);
        if (!Array.isArray(users)) {
          users = users ? [users] : [];
        }
        return (users as UserMinimized[]).map((user) => user.name).join('');
      },
      sortType: 'caseInsensitive',
      label,
      ValueCell: OwnersCell,
      iconProps: {
        icon: 'user' as IconValue,
      },
      FilterPageComponent: MultiSelectFilterPage,
      getFilterItems: (requests) => {
        return uniqBy<{ label: string, value: string }>(
          flatten(
            requests.map((request) => {
              let users = get(request, columnId);
              if (!Array.isArray(users)) {
                users = users ? [users] : [];
              }
              return (users as UserMinimized[]).map((user) => ({
                label: user.name,
                value: user._id,
              }));
            }),
          ),
          'value',
        ).sort((a, b) => a.label?.localeCompare(b.label, locale));
      },
      renderFilterItem: renderLabel,
      matchesFilter: (request, gridFilter) => {
        const selectedItems = gridFilter.filters.data[gridFilter.pageId] || [];

        if (isEmpty(selectedItems)) {
          return true;
        } else {
          let users = get(request, columnId);
          if (!Array.isArray(users)) {
            users = users ? [users] : [];
          }

          return users.some((user) => selectedItems.some((item) => user._id === item));
        }
      },
    };
  }, [locale]);

  const generateCurrencyColumnConfig = useCallback((columnId: string, label: string) => {
    return {
      _id: columnId,
      accessorKey: columnId,
      accessorFn: ({ request }) => get(request, columnId),
      sortAccessorFn: ({ request }) => get(request, columnId) ?? 0,
      label,
      ValueCell: withProps(CurrencyAmountOrDashCell, {
        getCurrencyCode: (row) => row.request.currencyCode,
      }),
    };
  }, []);

  const generateAwardScenarioColumnConfig = useCallback((columnId: string, label: string) => {
    const getAwardScenarioLabel = (awardScenario) => {
      if (!awardScenario) {
        return '';
      }
      if (awardScenario === 'legacyRequestLevelAward') {
        return t('request.awardScenarios.awardScenarios.requestLevelAward.label');
      } else {
        t(`request.awardScenarios.awardScenarios.${awardScenario}.label`);
      }
    };

    return {
      _id: columnId,
      accessorKey: columnId,
      accessorFn: ({ request }) => {
        const value = get(request, columnId);
        if (!Array.isArray(value)) {
          return getAwardScenarioLabel(value);
        } else {
          return value.map((awardScenario) => getAwardScenarioLabel(awardScenario)).join(', ');
        }
      },
      sortAccessorFn: ({ request }) => {
        const value = get(request, columnId);
        if (!Array.isArray(value)) {
          return getAwardScenarioLabel(value);
        } else {
          return value.map((awardScenario) => getAwardScenarioLabel(awardScenario)).join(', ');
        }
      },
      sortType: 'caseInsensitive',
      label,
      ValueCell: TextOrDashCell,
    };
  }, [t]);

  const columnConfigById: ColumnConfigById = useMemo(() => {
    return {
      subject: generateTextColumnConfig('subject', t('request.spendAndSavings.requestName'), 300),
      extendedStatus: {
        _id: 'extendedStatus',
        accessorKey: 'extendedStatus',
        accessorFn: ({ request }) => get(request, 'extendedStatus'),
        sortAccessorFn: ({ request }) => get(request, 'extendedStatus'),
        sortType: 'caseInsensitive',
        label: t('general.status'),
        ValueCell: RequestStatusCell,
        iconProps: {
          icon: 'circle-dashed' as IconValue,
        },
        FilterPageComponent: MultiSelectFilterPage,
        getFilterItems: (requests) =>
          requestStatusFilterItems.filter((item) =>
            requests.some((request) => request.extendedStatus === item.value),
          ),
        renderFilterItem: renderLabelWithIcon,
        matchesFilter: (request, gridFilter) => {
          const selectedItems =
            gridFilter.filters.data[gridFilter.pageId] || [];

          if (isEmpty(selectedItems)) {
            return true;
          } else {
            const value = request.extendedStatus;

            return selectedItems.some((item) => item === value);
          }
        },
      },
      creatorCompanyName: {
        _id: 'creatorCompanyName',
        accessorKey: 'creatorCompanyName',
        accessorFn: ({ request }) => ({
          _id: get(request, 'creatorCompanyId'),
          name: get(request, 'creatorCompanyName'),
        }),
        sortAccessorFn: ({ request }) => get(request, 'creatorCompanyName'),
        sortType: 'caseInsensitive',
        label: t('request.allRequestsTable.creatorCompany'),
        ValueCell: CompanyLogoAndNameCell,
        iconProps: {
          icon: 'building' as IconValue,
          isIconRegular: true,
        },
        FilterPageComponent: MultiSelectFilterPage,
        getFilterItems: (requests) => {
          return uniqBy<{ value: string; label: string }>(
            requests.map((request) => {
              return {
                value: get(request, 'creatorCompanyName'),
                label: get(request, 'creatorCompanyName'),
              };
            }),
            'label',
          ).sort((a, b) => a.label.localeCompare(b.label, locale));
        },
        renderFilterItem: renderLabel,
        matchesFilter: (request, gridFilter) => {
          const selectedItems =
            gridFilter.filters.data[gridFilter.pageId] || [];

          if (isEmpty(selectedItems)) {
            return true;
          } else {
            const value = get(request, 'creatorCompanyName');

            return selectedItems.some((item) => item === value);
          }
        },
      },

      createdBy: generateUsersColumnConfig('createdBy', t('createdBy', { ns: 'preQualification' })),

      draftToIssueMins: generateNumberColumnConfig('draftToIssueMins', t('request.allRequestsTable.draftToIssueTime')),
      issueToCloseMins: generateNumberColumnConfig('issueToCloseMins', t('request.allRequestsTable.issueToCloseTime')),
      senders: generateCompaniesColumnConfig('senders', t('request.allRequestsTable.collaboratorCompanies')),
      senderOwners: generateUsersColumnConfig('senderOwners', t('requests.owner_other')),
      senderUsers: generateUsersColumnConfig('senderUsers', t('requests.teamMember_other')),
      numStages: generateNumberColumnConfig('numStages', t('request.allRequestsTable.totalStages')),
      stageNames: {
        _id: 'stageNames',
        accessorKey: 'stageNames',
        accessorFn: ({ request }) => {
          return get(request, 'stageNames')
            .map(
              (stageName: string | null, index: number) =>
                stageName ? `${t('general.stage', { count: 1, ns: 'translation' })} ${index + 1}: ${stageName}` : `${t('general.stage', { count: 1, ns: 'translation' })} ${index + 1}`,
            )
            .join(', ');
        },
        sortType: 'caseInsensitive',
        label: t('request.allRequestsTable.stageNames'),
        ValueCell: TextOrDashCell,
      },
      stageDeadlines: {
        _id: 'stageDeadlines',
        accessorKey: 'stageDeadlines',
        accessorFn: ({ request }) => {
          return get(request, 'stageDeadlines')
            .map((stageDeadline, index) => {
              const formattedDeadline = stageDeadline
                ? localeFormatDate(
                    new Date(stageDeadline),
                    DateFormat.DD_MMM_YYYY_HH_MM_A_ZZZ,
                  )
                : t('general.notSpecified');
              return `${t('general.stage', { count: 1, ns: 'translation' })} ${index + 1}: ${formattedDeadline}`;
            })
            .join(', ');
        },
        sortAccessorFn: ({ request }) => request.stageDeadlines[0],
        label: t('request.allRequestsTable.stageDeadlines'),
        ValueCell: TextOrDashCell,
      },
      auctionStageUsed: generateBooleanColumnConfig('auctionStageUsed', t('request.allRequestsTable.auctionStageUsed')),
      numRevisions: generateNumberColumnConfig('numRevisions', t('request.allRequestsTable.totalRevisionsIssued')),
      awardedDate: generateDateColumnConfig('awardedDate', t('request.allRequestsTable.dateAwardedOrClosed')),
      awardedBy: generateUsersColumnConfig('awardedBy', t('request.allRequestsTable.awardedBy')),
      allAwardScenarios: generateAwardScenarioColumnConfig('allAwardScenarios', t('request.allRequestsTable.allAwardScenarios')),
      awardScenario: generateAwardScenarioColumnConfig('awardScenario', t('request.allRequestsTable.awardScenario')),
      createdAt: generateDateColumnConfig('createdAt', t('request.allRequestsTable.dateCreated')),
      firstIssuedAt: generateDateColumnConfig('firstIssuedAt', t('request.allRequestsTable.dateIssued')),
      bidDeadline: generateDateColumnConfig('bidDeadline', t('request.allRequestsTable.finalStageDeadline')),
      areLotsEnabled: generateBooleanColumnConfig('areLotsEnabled', t('request.allRequestsTable.areLotsEnabled')),
      lotCount: generateNumberColumnConfig('lotCount', t('request.allRequestsTable.lotCount')),
      lotNames: generateTextColumnConfig('lotNames', t('request.allRequestsTable.lotNames')),
      numSuppliers: generateNumberColumnConfig('numSuppliers', t('request.allRequestsTable.numSuppliers')),
      numCompleteBidsFinalStage: generateNumberColumnConfig('numCompleteBidsFinalStage', t('request.allRequestsTable.numCompleteBidsFinalStage')),
      numCompleteBidsEarlierStages: generateNumberColumnConfig('numCompleteBidsEarlierStages', t('request.allRequestsTable.numCompleteBidsEarlierStages')),
      numIncompleteBids: generateNumberColumnConfig('numIncompleteBids', t('request.allRequestsTable.numIncompleteBids')),
      numDeclinedBids: generateNumberColumnConfig('numDeclinedBids', t('request.allRequestsTable.numDeclinedBids')),
      numNoResponseBids: generateNumberColumnConfig('numNoResponseBids', t('request.allRequestsTable.numNoResponseBids')),
      numAwardedSuppliers: generateNumberColumnConfig('numAwardedSuppliers', t('request.allRequestsTable.numAwardedSuppliers')),
      numAwardedLots: generateNumberColumnConfig('numAwardedLots', t('request.allRequestsTable.numAwardedLots')),
      numNonAwardedLots: generateNumberColumnConfig('numNonAwardedLots', t('request.allRequestsTable.numNonAwardedLots')),
      numAwardedLineItems: generateNumberColumnConfig('numAwardedLineItems', t('request.allRequestsTable.numAwardedLineItems')),
      numSplitAwardedLineItems: generateNumberColumnConfig('numSplitAwardedLineItems', t('request.allRequestsTable.numSplitAwardedLineItems')),
      numNonAwardedLineItems: generateNumberColumnConfig('numNonAwardedLineItems', t('request.allRequestsTable.numNonAwardedLineItems')),
      awardedSuppliers: generateCompaniesColumnConfig('awardedSuppliers', t('requestOutcome.body.awardedSuppliers', { ns: 'email' })),
      areSpendAndSavingsEnabled: generateBooleanColumnConfig('areSpendAndSavingsEnabled', t('request.allRequestsTable.areSpendAndSavingsEnabled')),
      budgetedTotalValue: generateCurrencyColumnConfig('budgetedTotalValue', t('request.spendAndSavings.budgetedTotalValue')),
      confirmedFinalValue: generateCurrencyColumnConfig('confirmedFinalValue', t('request.spendAndSavings.confirmedFinalValue')),
      calculatedOrManualFinalValue: {
        _id: 'calculatedOrManualFinalValue',
        accessorKey: 'calculatedOrManualFinalValue',
        accessorFn: ({ request }) => {
          const { finalValueType } = request;
          return finalValueType ? t(`request.spendAndSavings.valueType.${finalValueType}`) : '';
        },
        sortAccessorFn: ({ request }) => {
          const { finalValueType } = request;
          return finalValueType ? t(`request.spendAndSavings.valueType.${finalValueType}`) : '';
        },
        label: t('request.spendAndSavings.calculatedOrManualFinalValue'),
        ValueCell: TextOrDashCell,
      },
      confirmedTotalSavings: generateCurrencyColumnConfig('confirmedTotalSavings', t('request.spendAndSavings.confirmedTotalSavings')),
      savingsCalculationMethod: {
        _id: 'savingsCalculationMethod',
        accessorKey: 'savingsCalculationMethod',
        accessorFn: ({ request }) => {
          const { savingsCalculationMethod } = request;
          return savingsCalculationMethod ? t(`request.spendAndSavings.totalSavingsCalculationMethods.${savingsCalculationMethod}`) : '';
        },
        sortAccessorFn: ({ request }) => {
          const { savingsCalculationMethod } = request;
          return savingsCalculationMethod ? t(`request.spendAndSavings.totalSavingsCalculationMethods.${savingsCalculationMethod}`) : '';
        },
        label: t('request.spendAndSavings.savingsCalculationMethod'),
        ValueCell: TextOrDashCell,
      },
      autoReferenceNumber: generateNumberColumnConfig('autoReferenceNumber', t('request.summary.automatedReference')),
      additionalReference: generateTextColumnConfig('additionalReference', t('request.summary.additionalReference')),
      originalCurrencyCode: generateTextColumnConfig('originalCurrencyCode', t('request.allRequestsTable.originalCurrencyCode')),
      description: generateTextColumnConfig('description', t('overview', { ns: 'general' })),
      productsAndServices: {
        _id: 'productsAndServices',
        accessorKey: 'productsAndServices',
        accessorFn: ({ request }) => {
          const { productsAndServices } = request;
          return productsAndServices ? productsAndServices.map((productAndServices) => `[${productAndServices._id}] ${productAndServices.title}`).join(', ') : '';
        },
        sortAccessorFn: ({ request }) => {
          const { productsAndServices } = request;
          return productsAndServices ? productsAndServices.map((productAndServices) => `[${productAndServices._id}] ${productAndServices.title}`).join(', ') : '';
        },
        sortType: 'caseInsensitive',
        label: t('request.allRequestsTable.productAndServiceTags'),
        ValueCell: TextOrDashCell,
      },
      isPubliclyAvailable: {
        _id: 'isPubliclyAvailable',
        accessorKey: 'isPubliclyAvailable',
        accessorFn: ({ request }) => {
          const { isPubliclyAvailable } = request;
          return isPubliclyAvailable ? t('request.visibility.publicVisibility') : t('request.visibility.privateVisibility');
        },
        sortAccessorFn: ({ request }) => {
          const { isPubliclyAvailable } = request;
          return isPubliclyAvailable ? t('request.visibility.publicVisibility') : t('request.visibility.privateVisibility');
        },
        label: t('request.allRequestsTable.isPubliclyAvailable'),
        ValueCell: TextOrDashCell,
        iconProps: {
          icon: 'eye' as IconValue,
        },
        FilterPageComponent: MultiSelectFilterPage,
        getFilterItems: () => requestVisibilityFilterItems,
        renderFilterItem: renderLabel,
        matchesFilter: (request, gridFilter) => {
          const selectedItems = gridFilter.filters.data[gridFilter.pageId] || [];

          if (isEmpty(selectedItems)) {
            return true;
          } else {
            const { isPubliclyAvailable } = request;
            const stringValue = isPubliclyAvailable ? t('request.visibility.publicVisibility') : t('request.visibility.privateVisibility');

            return selectedItems.some((item) => item === stringValue);
          }
        },
        getQueryParam: (selectedItems: string[]) => {
          if (isEmpty(selectedItems)) {
            return [true];
          }
          return selectedItems.map((item) => {
            if (item === t('request.visibility.publicVisibility')) {
              return true;
            } else {
              return false;
            }
          });
        },
      },
      hasBeenPublic: generateBooleanColumnConfig('hasBeenPublic', t('request.allRequestsTable.hasBeenPublic'), undefined, true),
      _id: generateTextColumnConfig('_id', t('request.allRequestsTable.requestId')),
      creatorCompanyId: generateTextColumnConfig('creatorCompanyId', t('request.allRequestsTable.creatorCompanyId')),
      createdByEmail: generateTextColumnConfig('createdByEmail', t('request.allRequestsTable.createdByEmail')),
      senderIds: generateTextColumnConfig('senderIds', t('request.allRequestsTable.senderIds')),
      senderOwnerEmails: generateTextColumnConfig('senderOwnerEmails', t('request.allRequestsTable.senderOwnerEmails')),
      senderUserEmails: generateTextColumnConfig('senderUserEmails', t('request.allRequestsTable.senderUserEmails')),
      recipientIds: generateTextColumnConfig('recipientIds', t('request.allRequestsTable.recipientIds')),
      awardedSuppliersIds: generateTextColumnConfig('awardedSuppliersIds', t('request.allRequestsTable.awardedSupplierIds')),

      tags: {
        _id: 'tags',
        accessorKey: 'tags',
        label: t('request.allRequestsTable.tags'),
        accessorFn: ({ request }) => {
          const { tags: requestTags } = request;
          if (requestTags) {
            return requestTags
              .sort(
                (tagA, tagB) =>
                  orderedTagsById[tagA._id] > orderedTagsById[tagB._id],
              )
              .map((requestTag) => {
                const ancestorsNames = getRequestTagAncestorsNames(
                  requestTag,
                  tagById,
                );
                const ancestorNamesPrefix = ancestorsNames
                  .map((ancestorName) => `${ancestorName}/`)
                  .join('');

                return `${ancestorNamesPrefix}${requestTag.name}`;
              })
              .join(', ');
          } else {
            return '';
          }
        },
        ValueCell: TextOrDashCell,
        iconProps: {
          icon: 'tag',
        },
        FilterPageComponent: MultiSelectFilterPage,
        getFilterItems: () => {
          return tags.map((tag) => ({
            label: tag.name,
            value: tag._id,
            tag,
          }));
        },
        renderPreItemContent: (item: { tag: RequestTag }, index) => !item.tag?.parentTagId && index > 0 ? (<PanelDivider />) : null,
        renderFilterItem: (item: { tag: RequestTag }) => renderRequestTagLabel(item.tag, tagById),
        matchesFilter: (request, gridFilter) => {
          const selectedItems = gridFilter.filters.data[gridFilter.pageId] || [];

          if (isEmpty(selectedItems)) {
            return true;
          } else {
            const { tags: requestTags } = request;

            return requestTags.some((requestTag) => selectedItems.some((selectedTagId) => requestTag._id === selectedTagId));
          }
        },
      },
    } as const;
  }, [
    generateTextColumnConfig,
    t,
    generateUsersColumnConfig,
    generateNumberColumnConfig,
    generateCompaniesColumnConfig,
    generateBooleanColumnConfig,
    generateDateColumnConfig,
    generateAwardScenarioColumnConfig,
    generateCurrencyColumnConfig,
    requestStatusFilterItems,
    locale,
    requestVisibilityFilterItems,
    orderedTagsById,
    tagById,
    tags,
  ]);

  return columnConfigById;
};
