import * as React from 'react';
import styled from 'styled-components';
import { Box, Text, Flex } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { mergeRefs } from '../popup/usePopover';

const ClampBase = styled.div`
  display: -webkit-box;
  //
  // TODO box-orient is currently non-standard and depreacated 
  // (https://developer.mozilla.org/en-US/docs/Web/CSS/box-orient)
  //
  // However, there are no current clean alternative to achieve
  // multiline text overflow with ellipsis.
  //
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const isTextClamped = (element: Element) => element.scrollHeight > element.clientHeight;

export interface ClampProps {
  lines: number;
  style?: React.CSSProperties;
  setIsClamped?: (isClamped: boolean) => void;
  children?: React.ReactNode;
}

export const Clamp = React.forwardRef(({ lines, style = {}, setIsClamped, children }: ClampProps, ref) => {
  const [clampedContainer, setClampedContainer] = React.useState<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (!clampedContainer || !setIsClamped) return;

    const observer = new ResizeObserver((entries) => {
      // This `setTimeout` wrapper with ms: 0 prevents the
      // `ResizeObserver loop completed with undelivered notifications`
      // error by placing the `setIsClamped` call at the end of the JS
      // event loop.
      setTimeout(() => {
        const element = entries[0].target as Element;
        if (isTextClamped(element)) {
          setIsClamped?.(true);
        } else {
          setIsClamped?.(false);
        }
      }, 0);
    });
    observer.observe(clampedContainer);

    return () => observer.disconnect();
  }, [clampedContainer, setIsClamped]);

  return (
    <ClampBase ref={mergeRefs([setClampedContainer, ref])} style={{ WebkitLineClamp: lines, ...style }}>
      {children}
    </ClampBase>
  );
});

type Clamp2Props = {
  lines: number;
  children?: React.ReactNode;
};

export const SeeMoreText = styled(Text)`
  display: inline;
  cursor: pointer;
  color: ${props => props.theme.colors.primary};
  font-size: inherit;
`;

const FloatButtonContainer = styled.div`
  float: right;
  height: 100%; 
  display: flex; 
  align-items: flex-end; 
  shape-outside: inset(calc(100% - 10px) 0 0); 
`;

/**
 * Clamp2 component is used to clamp text to a certain number of lines using JavaScript.
 *
 * Compared to Clamp component, Clamp2 allows to show a "See more" button inline with the text.
 */
export const Clamp2 = ({ lines, children }: Clamp2Props) => {
  const { t } = useTranslation('general');
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
  const [isClamped, setIsClamped] = React.useState<boolean>(false);

  if (isExpanded) {
    return (
      <Box>
        {children}
        <SeeMoreText onClick={() => setIsExpanded(false)} ml={2}>{t('seeLess')}</SeeMoreText>
      </Box>
    );
  } else {
    return (
      <Flex>
        <Clamp lines={lines} setIsClamped={setIsClamped}>
          {isClamped && <SeeMoreText onClick={() => setIsExpanded(true)}><FloatButtonContainer>{t('seeMore')}</FloatButtonContainer></SeeMoreText>}
          {children}
        </Clamp>
      </Flex>
    );
  }
};
