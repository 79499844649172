import { SortingDirection } from '@deepstream/common';
import { AllRequestsGridColumnId } from '@deepstream/common/reporting';
import { GridViewConfig } from '../../ui/ExchangeDefsGrid/gridViews';
import { AllRequestsGridGroupingCriterion, AllRequestsGridViewId } from './allRequestsGridTypes';

export const orderedViewIds = [
  'myView',
] as AllRequestsGridViewId[];

export const allRequestsGridAvailableViewIds = ['myView'] as AllRequestsGridViewId[];

export const allRequestsGridAvailableColumnIds: AllRequestsGridColumnId[] = [
  'subject',
  'extendedStatus',
  'creatorCompanyName',
  'createdBy',
  'createdAt',
  'firstIssuedAt',
  'draftToIssueMins',
  'issueToCloseMins',
  'senders',
  'senderOwners',
  'senderUsers',
  'numStages',
  'stageNames',
  'stageDeadlines',
  'bidDeadline',
  'areLotsEnabled',
  'lotCount',
  'lotNames',
  'auctionStageUsed',
  'numRevisions',
  'awardedDate',
  'awardedBy',
  'allAwardScenarios',
  'awardScenario',
  'numSuppliers',
  'numCompleteBidsFinalStage',
  'numCompleteBidsEarlierStages',
  'numIncompleteBids',
  'numDeclinedBids',
  'numNoResponseBids',
  'numAwardedSuppliers',
  'numAwardedLots',
  'numNonAwardedLots',
  'numAwardedLineItems',
  'numSplitAwardedLineItems',
  'numNonAwardedLineItems',
  'awardedSuppliers',
  'areSpendAndSavingsEnabled',
  'budgetedTotalValue',
  'confirmedFinalValue',
  'calculatedOrManualFinalValue',
  'confirmedTotalSavings',
  'savingsCalculationMethod',
  'autoReferenceNumber',
  'additionalReference',
  'originalCurrencyCode',
  'description',
  'productsAndServices',
  'hasBeenPublic',
  'isPubliclyAvailable',
  '_id',
  'creatorCompanyId',
  'createdByEmail',
  'senderIds',
  'senderOwnerEmails',
  'senderUserEmails',
  'recipientIds',
  'awardedSuppliersIds',
  'tags',
] as AllRequestsGridColumnId[];

export const allRequestsReportingGridAvailableFilterIds = allRequestsGridAvailableColumnIds;

type AllRequestsReportingViewConfig = GridViewConfig<AllRequestsGridGroupingCriterion, AllRequestsGridColumnId>;

export const viewConfigById: Record<string, AllRequestsReportingViewConfig> = {
  myView: {
    excludedColumnIds: [],
    excludedFilterIds: [],
    defaultColumnIds: allRequestsGridAvailableColumnIds,
    defaultSorting: [
      {
        columnId: 'extendedStatus',
        direction: SortingDirection.ASC,
      },
    ],
  },
};
