export const FilterComparisonOperator = {
  LT: 'lt',
  LTE: 'lte',
  EQ: 'eq',
  GTE: 'gte',
  GT: 'gt',
  NEQ: 'ne',
} as const;

export type FilterComparisonOperatorType = typeof FilterComparisonOperator[keyof typeof FilterComparisonOperator];

export const ActiveStageFilterOperator = {
  ...FilterComparisonOperator,
  FINAL_STAGE: 'final_stage',
} as const;

export type ActiveStageFilterOperatorType = typeof ActiveStageFilterOperator[keyof typeof ActiveStageFilterOperator];

export type ActiveStageFilter = {
  stageNumber?: number;
  operator: ActiveStageFilterOperatorType;
};

export const FinalDeadlineFilterOperator = {
  ...FilterComparisonOperator,
  HAS_PASSED: 'has_passed',
  HAS_NOT_PASSED: 'has_not_passed',
} as const;

export type FinalDeadlineFilterOperatorType = typeof FinalDeadlineFilterOperator[keyof typeof FinalDeadlineFilterOperator];

export type FinalDeadlineFilter = {
  deadline?: Date;
  operator: FinalDeadlineFilterOperatorType;
};

export enum ApplicabilityFilterOperator {
  NOT_APPLICABLE = 'notApplicable',
  NOT_NOT_APPLICABLE = 'notNotApplicable',
}

export type NumberComparisonFilter = {
  value: number;
  operator: FilterComparisonOperatorType;
};

export type DateComparisonFilter = {
  selectedDateStart: number;
  selectedDateEnd: number;
  operator: FilterComparisonOperatorType;
};
