import {
    compact,
    first,
    isEmpty,
    omit,
    pickBy,
    difference,
} from 'lodash';
import { useMemo } from 'react';
import { immutableSet } from '@deepstream/common';
import { immutableUpdate } from '@deepstream/utils';
import { AllRequestsGridColumnId } from '@deepstream/common/reporting';
import { renderLabel } from '../../RequestsTable';
import {
    renderLabeledSortDirection,
    renderTruncatedLabeledSortCriterion,
    useSortDirectionItems,
} from '../../sorting';
import { useLocalStorageState } from '../../useLocalStorageState';

import { GridDisplayConfig, SelectColumnItem, SelectViewItem, StoredGridConfig, StoredViewConfig } from '../../ui/ExchangeDefsGrid/gridViews';
import { } from '../Request/Sent/useSuppliersGridConfig';
import { AllRequestsGridViewId } from './allRequestsGridTypes';
import { allRequestsGridAvailableViewIds, viewConfigById } from './allRequestsGridViews';

const ignoredColumnIds = [
  'subject',
] as AllRequestsGridColumnId[];

export const useAllRequestsGridConfig = ({
  storageKey,
  availableColumnIds,
  columnConfigById,
}: {
  storageKey: string;
  availableColumnIds: string[];
  columnConfigById: Record<string, { _id: string; label: string } | null>;
}): GridDisplayConfig<AllRequestsGridViewId> => {
  const viewItems = allRequestsGridAvailableViewIds.map((viewId) => ({
    value: viewId,
    // The view is not visible to the user, no need for a label
    label: '',
  }));

  const columnItems = useMemo(() => {
    return difference(availableColumnIds, ignoredColumnIds).map((columnId) => ({
      _id: columnId,
      label: columnConfigById[columnId]!.label,
    }));
  }, [availableColumnIds, columnConfigById]);

  const sortDirectionItems = useSortDirectionItems(true);

  const [config, setConfig] = useLocalStorageState<StoredGridConfig<AllRequestsGridViewId>>({
    key: storageKey,
    defaultValue: {
      viewIds: viewItems.slice(0, 1).map(({ value }) => value),
      configByViewId: {} as Record<Partial<AllRequestsGridViewId>, StoredViewConfig>,
    },
    mapInitialValue: (initialConfig) => {
      const initialViewId = first(initialConfig?.viewIds);
      const initialConfigByViewId = initialConfig?.configByViewId || {};

      return {
        viewIds:
          initialViewId &&
          viewItems.some(({ value }) => value === initialViewId)
            ? [initialViewId]
            : !isEmpty(viewItems)
              ? [first(viewItems)!.value]
              : [],
        configByViewId: pickBy(initialConfigByViewId, (_value, key) =>
          viewItems.some(({ value }) => value === key),
        ) as Record<Partial<AllRequestsGridViewId>, StoredViewConfig>,
      };
    },
  });

  return useMemo(() => {
    const selectedViewId = first(config.viewIds)!;

    const viewConfig = selectedViewId
      ? config.configByViewId[selectedViewId]
      : null;

    const availableColumnItems = columnItems.filter(
      (item) =>
        !selectedViewId ||
        !viewConfigById[selectedViewId].excludedColumnIds.includes(
          item._id as AllRequestsGridColumnId,
        ),
    );

    const selectedColumnIds = selectedViewId
      ? viewConfig?.columnIds || viewConfigById[selectedViewId].defaultColumnIds
      : [];

    const selectedColumnItems = availableColumnItems.filter((item) =>
      selectedColumnIds.includes(item._id as string),
    );

    const sortCriteriaItems = [
      {
        _id: columnConfigById.subject!._id,
        label: columnConfigById.subject!.label,
      },
      ...selectedColumnItems,
    ].map((item) => ({
      accessor: item._id,
      label: item.label,
    }));
    const selectedSorting = viewConfig?.sorting
      ? viewConfig.sorting
          .map(({ columnId, direction }) => ({
            criterion: sortCriteriaItems.find(
              (item) => item.accessor === columnId,
            )!,
            direction: sortDirectionItems.find(
              (item) => item.direction === direction,
            )!,
          }))
          .filter((item) => item.criterion && item.direction)
      : [];
    return {
      view: {
        itemToString: (item: SelectViewItem<AllRequestsGridViewId> | null) => (item ? item.label : ''),
        items: viewItems,
        selectedItems: compact(
          config.viewIds.map((viewId) =>
            viewItems.find((item) => item.value === viewId),
          ),
        ),
        onChange: (viewItems) =>
          setConfig((previousConfig) => ({
            ...previousConfig,
            viewIds: viewItems.map(({ value }) => value),
          })),
      },
      columns: {
        itemToString: (item: SelectColumnItem | null) => (item ? item._id : ''),
        renderItem: renderLabel,
        items: availableColumnItems,
        selectedItems: selectedColumnItems,
        onChange: (columnItems) => {
          if (selectedViewId) {
            setConfig((previousConfig) => {
              return immutableSet(
                previousConfig,
                ['configByViewId', selectedViewId, 'columnIds'],
                columnItems.map(({ _id }) => _id),
              );
            });
          }
        },
        idProp: '_id',
      },
      sorting: {
        renderCriteriaItem: renderTruncatedLabeledSortCriterion,
        renderDirectionItem: renderLabeledSortDirection,
        criteriaItems: sortCriteriaItems,
        directionItems: sortDirectionItems,
        selectedSorting,
        onSortingChange: (sorting) => {
          setConfig((previousConfig) => {
            return immutableSet(
              previousConfig,
              ['configByViewId', selectedViewId, 'sorting'],
              sorting.map((item) => ({
                columnId: item.criterion.accessor,
                direction: item.direction.direction,
              })),
            );
          });
        },
      },
      filters: {
        data: viewConfig?.filters || {},
        update: (filterId, newFilters) => {
          setConfig((previousConfig) => {
            if (isEmpty(newFilters)) {
              return immutableUpdate(
                previousConfig,
                ['configByViewId', selectedViewId, 'filters'],
                (previousFilters) => omit(previousFilters, [filterId]),
              );
            } else {
              return immutableSet(
                previousConfig,
                ['configByViewId', selectedViewId, 'filters', filterId],
                newFilters,
              );
            }
          });
        },
        clear: () => {
          setConfig((previousConfig) => {
            return immutableSet(
              previousConfig,
              ['configByViewId', selectedViewId, 'filters'],
              {},
            );
          });
        },
      },
    };
  }, [
    config,
    columnItems,
    viewItems,
    sortDirectionItems,
    setConfig,
    columnConfigById.subject,
  ]);
};
