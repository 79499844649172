import { useMemo, Fragment } from 'react';
import { Text } from 'rebass/styled-components';
import { getRequestTagAncestorsNames, RequestTag } from '@deepstream/common/rfq-utils';

export const renderRequestTagLabel = (tag: RequestTag, tagById: { [id: string]: RequestTag }) => {
  // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
  const requestTag = tagById[tag._id];
  const ancestorsNames = getRequestTagAncestorsNames(requestTag, tagById);
  if (ancestorsNames.length === 0) {
    return (
      <>
        {requestTag.name}
      </>
    );
  }

  return (
    <span>
      <Text fontSize="14px" color="gray" display="inline">
        {ancestorsNames.map((ancestorsName) => <Fragment key={ancestorsName}>{ancestorsName}/&#8203;</Fragment>)}
      </Text>
      {requestTag.name}
    </span>
  );
};

const RequestTagLabel = ({ tag, tagById }: { tag: RequestTag; tagById: { [id: string]: RequestTag } }) => {
  const ancestorsNames = useMemo(() => getRequestTagAncestorsNames(tag, tagById), [tag, tagById]);
  if (ancestorsNames.length === 0) {
    return <>{tag.name}</>;
  }

  return (
    <span>
      <Text fontSize="14px" color="gray" display="inline">
        {ancestorsNames.map((ancestorsName) => <Fragment key={ancestorsName}>{ancestorsName}/&#8203;</Fragment>)}
      </Text>
      {tag.name}
    </span>
  );
};

export default RequestTagLabel;
