import { TFunction } from 'i18next';
import { compact, isEmpty, isString, reduce, values, map, toLower } from 'lodash';

import { DsError, RenderNotificationError } from '@deepstream/errors';
import { NotificationAction, NotificationDomain } from './types';
import { ActionType, ExchangeType } from '../rfq-utils';
import { documentExchangeTypes } from '../exchangesConfig';

const actionMessageTranslationKeys = {
  [ActionType.NONE]: {
    // Set default comment translation for all exchange types
    ...reduce(
      values(ExchangeType),
      (accumulator, exchangeKey) => ({
        ...accumulator,
        [exchangeKey]: 'actionMessage.none.genericExchange',
      }),
      {},
    ),

    // Overrides
    [ExchangeType.CONTRACT]: 'actionMessage.none.contract',
  },
  [ActionType.OBSOLETE_ACTION]: {
    // Set default comment translation for all exchange types
    ...reduce(
      values(ExchangeType),
      (accumulator, exchangeKey) => ({
        ...accumulator,
        [exchangeKey]: 'actionMessage.obsolete-action.genericExchange',
      }),
      {},
    ),

    // Overrides
    [ExchangeType.INFORMATION]: 'actionMessage.obsolete-action.document',
    [ExchangeType.ACCEPT_CLOSED]: 'actionMessage.obsolete-action.document',
    [ExchangeType.ACCEPT]: 'actionMessage.obsolete-action.document',
    [ExchangeType.COMPLETE_OR_SIGN]: 'actionMessage.obsolete-action.document',
    [ExchangeType.COMPLETE_OR_SIGN_LOCKED]: 'actionMessage.obsolete-action.document',
    [ExchangeType.COMPLETE_OR_SIGN_CLOSED]: 'actionMessage.obsolete-action.document',
    [ExchangeType.DOCUMENT_REQUEST]: 'actionMessage.obsolete-action.document',
    [ExchangeType.DOCUMENT_REQUEST_CLOSED]: 'actionMessage.obsolete-action.document',
    [ExchangeType.DOCUMENT_REQUEST_LOCKED]: 'actionMessage.obsolete-action.document',
  },
  [ActionType.INITIATE]: {
    [ExchangeType.DOCUMENT_REQUEST]: 'actionMessage.initiate.requestDoc',
    [ExchangeType.DOCUMENT_REQUEST_CLOSED]: 'actionMessage.initiate.requestDocClosed',
    [ExchangeType.DOCUMENT_REQUEST_LOCKED]: 'actionMessage.initiate.requestDocLocked',
    [ExchangeType.ACCEPT]: 'actionMessage.initiate.agreeDeviation',
    [ExchangeType.ACCEPT_CLOSED]: 'actionMessage.initiate.acceptClosed',
    [ExchangeType.INFORMATION]: 'actionMessage.initiate.information',
    [ExchangeType.COMPLETE_OR_SIGN]: 'actionMessage.initiate.completeAndSign',
    [ExchangeType.COMPLETE_OR_SIGN_CLOSED]: 'actionMessage.initiate.completeAndSignClosed',
    [ExchangeType.COMPLETE_OR_SIGN_LOCKED]: 'actionMessage.initiate.completeAndSignLocked',
    [ExchangeType.LINE_ITEM]: 'actionMessage.initiate.lineItem',
  },
  [ActionType.ACCEPT]: {
    [ExchangeType.DOCUMENT_REQUEST]: 'actionMessage.accept.requestDoc',
    [ExchangeType.ACCEPT_CLOSED]: 'actionMessage.accept.acceptClosed',
    [ExchangeType.ACCEPT]: 'actionMessage.accept.agreeDeviation',
    [ExchangeType.COMPLETE_OR_SIGN]: 'actionMessage.accept.completeAndSign',
    [ExchangeType.LINE_ITEM]: 'actionMessage.accept.lineItem',
    [ExchangeType.DELIVERY_DEADLINE]: 'actionMessage.accept.deliveryBy',
    [ExchangeType.DELIVERY_ADDRESS]: 'actionMessage.accept.deliveryTo',
    [ExchangeType.INCOTERMS]: 'actionMessage.accept.incoterms',
    [ExchangeType.PAYMENT_STAGE]: 'actionMessage.accept.paymentStage',
    [ExchangeType.AUCTION_TERMS]: 'actionMessage.accept.auctionTerms',

    // Contract types
    [ExchangeType.CONTRACT]: 'actionMessage.accept.contract',
    [ExchangeType.APPROVAL]: 'actionMessage.accept.contractPageApproval',
  },
  [ActionType.REJECT]: {
    [ExchangeType.DOCUMENT_REQUEST]: 'actionMessage.reject.requestDoc',
    [ExchangeType.DOCUMENT_REQUEST_LOCKED]: 'actionMessage.reject.requestDocLocked',
    [ExchangeType.DOCUMENT_REQUEST_CLOSED]: 'actionMessage.reject.requestDocClosed',
    [ExchangeType.COMPLETE_OR_SIGN]: 'actionMessage.reject.completeAndSign',
    [ExchangeType.COMPLETE_OR_SIGN_CLOSED]: 'actionMessage.reject.completeAndSignClosed',
    [ExchangeType.ACCEPT]: 'actionMessage.reject.agreeDeviation',
    [ExchangeType.LINE_ITEM]: 'actionMessage.reject.lineItem',
    [ExchangeType.PAYMENT_STAGE]: 'actionMessage.reject.paymentStage',

    // Contract types
    [ExchangeType.CONTRACT]: 'actionMessage.reject.contract',
    [ExchangeType.APPROVAL]: 'actionMessage.reject.contractPageApproval',
  },
  [ActionType.DEVIATE]: {
    [ExchangeType.ACCEPT]: 'actionMessage.deviate.agreeDeviation',
    [ExchangeType.ACCEPT_CLOSED]: 'actionMessage.deviate.acceptClosed',
    [ExchangeType.COMPLETE_OR_SIGN]: 'actionMessage.deviate.completeAndSign',
    [ExchangeType.DOCUMENT_REQUEST]: 'actionMessage.deviate.requestDoc',
    [ExchangeType.DELIVERY_DEADLINE]: 'actionMessage.deviate.deliveryBy',
    [ExchangeType.DELIVERY_ADDRESS]: 'actionMessage.deviate.deliveryTo',
    [ExchangeType.INCOTERMS]: 'actionMessage.deviate.incoterms',

    // Contract types
    [ExchangeType.CONTRACT]: 'actionMessage.deviate.contract',
  },
  [ActionType.SUBMIT]: {
    [ExchangeType.COMPLETE_OR_SIGN]: 'actionMessage.submit.completeAndSign',
    [ExchangeType.COMPLETE_OR_SIGN_CLOSED]: 'actionMessage.submit.completeAndSignClosed',
    [ExchangeType.COMPLETE_OR_SIGN_LOCKED]: 'actionMessage.submit.completeAndSignLocked',
    [ExchangeType.DOCUMENT_REQUEST]: 'actionMessage.submit.requestDoc',
    [ExchangeType.DOCUMENT_REQUEST_CLOSED]: 'actionMessage.submit.requestDocClosed',
    [ExchangeType.DOCUMENT_REQUEST_LOCKED]: 'actionMessage.submit.requestDocLocked',
    [ExchangeType.CURRENCY]: 'actionMessage.submit.currency',
    [ExchangeType.FEES]: 'actionMessage.submit.fees',
    [ExchangeType.HIRE_PERIOD]: 'actionMessage.submit.hirePeriod',
    [ExchangeType.INCLUSIONS]: 'actionMessage.submit.inclusionsAndExclusions',
    [ExchangeType.TERMS]: 'actionMessage.submit.additionalTerms',
    [ExchangeType.LINE_ITEM]: 'actionMessage.submit.lineItem',
    [ExchangeType.QUESTION]: 'actionMessage.submit.question',
    [ExchangeType.EVALUATION_CRITERION]: 'actionMessage.submit.evaluationCriterion',

    // Contract types
    [ExchangeType.CONTRACT]: 'actionMessage.submit.contract',
  },
  [ActionType.REVISE]: {
    [ExchangeType.FEES]: 'actionMessage.revise.fees',
    [ExchangeType.HIRE_PERIOD]: 'actionMessage.revise.hirePeriod',
    [ExchangeType.INCLUSIONS]: 'actionMessage.revise.inclusionsAndExclusions',
    [ExchangeType.TERMS]: 'actionMessage.revise.additionalTerms',
    [ExchangeType.LINE_ITEM]: 'actionMessage.revise.lineItem',
  },
  [ActionType.CLOSE]: {
    [ExchangeType.CHAT]: 'actionMessage.close.chat',
    [ExchangeType.CHAT_NO_RECEIVER_UPLOAD]: 'actionMessage.close.chatNoSupplierUpload',
    [ExchangeType.CHAT_ONLY_RECEIVER_UPLOAD]: 'actionMessage.close.chatOnlyReceiverUpload',
  },
  [ActionType.RESOLVE]: {
    [ExchangeType.COMPLETE_OR_SIGN]: 'actionMessage.resolve.completeAndSign',
    [ExchangeType.COMPLETE_OR_SIGN_LOCKED]: 'actionMessage.resolve.completeAndSignLocked',
    [ExchangeType.COMPLETE_OR_SIGN_CLOSED]: 'actionMessage.resolve.completeAndSignClosed',
    [ExchangeType.ACCEPT]: 'actionMessage.resolve.agreeDeviation',
    [ExchangeType.ACCEPT_CLOSED]: 'actionMessage.resolve.acceptClosed',
    [ExchangeType.CLARIFICATION]: 'actionMessage.resolve.clarification',
    [ExchangeType.DOCUMENT_REQUEST]: 'actionMessage.resolve.requestDoc',
    [ExchangeType.DOCUMENT_REQUEST_LOCKED]: 'actionMessage.resolve.requestDocLocked',
    [ExchangeType.DOCUMENT_REQUEST_CLOSED]: 'actionMessage.resolve.requestDocClosed',
  },
  [ActionType.REFER_TO_BULLETIN]: 'actionMessage.refer-to-bulleting',
  [ActionType.MARK_AS_AGREED]: 'actionMessage.mark-as-agreed',
  [ActionType.REENABLE_RESPONSES]: {
    [ExchangeType.INFORMATION]: 'actionMessage.reenable-responses.information',
    [ExchangeType.ACCEPT]: 'actionMessage.reenable-responses.agreeDeviation',
    [ExchangeType.ACCEPT_CLOSED]: 'actionMessage.reenable-responses.acceptClosed',
    [ExchangeType.COMPLETE_OR_SIGN]: 'actionMessage.reenable-responses.completeAndSign',
    [ExchangeType.COMPLETE_OR_SIGN_CLOSED]: 'actionMessage.reenable-responses.completeAndSignClosed',
    [ExchangeType.COMPLETE_OR_SIGN_LOCKED]: 'actionMessage.reenable-responses.completeAndSignLocked',
    [ExchangeType.DOCUMENT_REQUEST]: 'actionMessage.reenable-responses.requestDoc',
    [ExchangeType.DOCUMENT_REQUEST_CLOSED]: 'actionMessage.reenable-responses.requestDocClosed',
    [ExchangeType.DOCUMENT_REQUEST_LOCKED]: 'actionMessage.reenable-responses.requestDocLocked',
    [ExchangeType.FEES]: 'actionMessage.reenable-responses.fees',
    [ExchangeType.HIRE_PERIOD]: 'actionMessage.reenable-responses.hirePeriod',
    [ExchangeType.INCLUSIONS]: 'actionMessage.reenable-responses.inclusionsAndExclusions',
    [ExchangeType.TERMS]: 'actionMessage.reenable-responses.additionalTerms',
    [ExchangeType.LINE_ITEM]: 'actionMessage.reenable-responses.lineItem',
    [ExchangeType.QUESTION]: 'actionMessage.reenable-responses.question',
  },
  [ActionType.DISABLE_RESPONSES]: {
    [ExchangeType.INFORMATION]: 'actionMessage.disable-responses.information',
    [ExchangeType.ACCEPT]: 'actionMessage.disable-responses.agreeDeviation',
    [ExchangeType.ACCEPT_CLOSED]: 'actionMessage.disable-responses.acceptClosed',
    [ExchangeType.COMPLETE_OR_SIGN]: 'actionMessage.disable-responses.completeAndSign',
    [ExchangeType.COMPLETE_OR_SIGN_CLOSED]: 'actionMessage.disable-responses.completeAndSignClosed',
    [ExchangeType.COMPLETE_OR_SIGN_LOCKED]: 'actionMessage.disable-responses.completeAndSignLocked',
    [ExchangeType.DOCUMENT_REQUEST]: 'actionMessage.disable-responses.requestDoc',
    [ExchangeType.DOCUMENT_REQUEST_CLOSED]: 'actionMessage.disable-responses.requestDocClosed',
    [ExchangeType.DOCUMENT_REQUEST_LOCKED]: 'actionMessage.disable-responses.requestDocLocked',
    [ExchangeType.FEES]: 'actionMessage.disable-responses.fees',
    [ExchangeType.HIRE_PERIOD]: 'actionMessage.disable-responses.hirePeriod',
    [ExchangeType.INCLUSIONS]: 'actionMessage.disable-responses.inclusionsAndExclusions',
    [ExchangeType.TERMS]: 'actionMessage.disable-responses.additionalTerms',
    [ExchangeType.LINE_ITEM]: 'actionMessage.disable-responses.lineItem',
    [ExchangeType.QUESTION]: 'actionMessage.disable-responses.question',
  },
  [ActionType.COUNTERSIGN]: {
    [ExchangeType.COMPLETE_OR_SIGN]: 'actionMessage.countersign.document',
    [ExchangeType.COMPLETE_OR_SIGN_CLOSED]: 'actionMessage.countersign.document',
    [ExchangeType.COMPLETE_OR_SIGN_LOCKED]: 'actionMessage.countersign.document',

    [ExchangeType.CONTRACT]: 'actionMessage.countersign.contract',
  },
  [ActionType.REPLACE]: {
    [ExchangeType.CONTRACT]: 'actionMessage.replace.contract',
  },
  [ActionType.AGREE]: {
    [ExchangeType.CONTRACT]: 'actionMessage.agree.contract',
  },
  [ActionType.UPLOAD_DOCUMENT]: {
    [ExchangeType.CONTRACT]: 'actionMessage.upload-document.contract',
  },
  [ActionType.APPROVE_DOCUMENT]: {
    [ExchangeType.CONTRACT]: 'actionMessage.approve-document.contract',
  },
};

const getActionMessage = (notification, t: TFunction) => {
  const { from, meta } = notification;

  const value = actionMessageTranslationKeys[meta.actionType];
  const actionMessagePrefix = t('actionMessagePrefix', { from, ns: 'notifications' });

  const { exchangeName: rawExchangeName, ...rest } = meta;

  const exchangeName = (
    rawExchangeName ||
    t(`request.exchangeType.${meta.exchangeType}`, { ns: 'translation' })
  );

  if (isString(value)) {
    return `${actionMessagePrefix} ${t(value, { exchangeName, ...rest })}`;
  }

  const nestedValue = value?.[meta.exchangeType];

  if (isString(nestedValue)) {
    return `${actionMessagePrefix} ${t(nestedValue, { exchangeName, ...rest })}`;
  }

  const message = `Could not find action message for action type "${meta.actionType}" and exchange type "${meta.exchangeType}"`;
  throw new DsError(message, { actionType: meta.actionType, exchangeType: meta.exchangeType });
};

const messagesNotificationTable = {
  [NotificationDomain.RFQ_RECEIVED]: {
    // In some older notifications (2021/2022), the `notification.from.companyName`
    // property is not present, so we render a fallback notification.
    [NotificationAction.RFQ_CREATED]: (notification, t) => notification.from.companyName
      ? t('notification.rfqReceived.rfqCreated.withCompanyName', notification)
      : t('notification.rfqReceived.rfqCreated.withoutCompanyName', notification),
    [NotificationAction.RFQ_UPDATED]: (notification, t) => t('notification.rfqReceived.rfqUpdated', notification),
    [NotificationAction.RFQ_AWARDED]: (notification, t) => t('notification.rfqReceived.rfqBidAwarded', notification),
    [NotificationAction.BIDS_REJECTED]: (notification, t) => t('notification.rfqReceived.rfqUnsuccessful', notification),
    [NotificationAction.BIDS_REINSTATED]: (notification, t) => t('notification.rfqReceived.bidsReinstated', notification),
    [NotificationAction.RFQ_CLOSED]: (notification, t) => t('notification.rfqReceived.rfqUnsuccessful', notification),
    [NotificationAction.RFQ_LOST]: (notification, t) => t('notification.rfqReceived.rfqUnsuccessful', notification),
    [NotificationAction.NEW_MEMBER]: (notification, t) => notification.to.userId === notification.meta.memberAffected._id
        ? t('notification.rfqReceived.newMember.currentUser', notification)
        : t('notification.rfqReceived.newMember.otherUser', notification),
    [NotificationAction.REMOVED_MEMBER]: (notification, t) => t('notification.rfqReceived.removedMember', notification),
    [NotificationAction.RFQ_MEMBER_ROLES_UPDATED]: (notification, t) => t('notification.rfqReceived.rfqMemberRolesUpdated', notification),
    [NotificationAction.BID_SENT]: (notification, t) => t('notification.rfqReceived.bidSent', notification),
    [NotificationAction.BULLETIN_QUESTION_POSTED]: (notification, t) => t('notification.rfqReceived.bulletinQuestionPosted', notification),
    [NotificationAction.BULLETIN_SIMPLE_CLARIFICATION]: (notification, t) => t('notification.rfqReceived.bulletinSimpleClarificationPosted', notification),
    [NotificationAction.BULLETIN_ANSWER_TO_QUESTION_POSTED]: (notification, t) => t('notification.rfqReceived.bulletinAnswerToQuestionPosted', notification),
    [NotificationAction.BULLETIN_CLARIFICATION_TO_QUESTION_POSTED]: (notification, t) => t('notification.rfqReceived.bulletinClarificationToQuestionPosted', notification),
    [NotificationAction.EXCHANGE_REPLY_SENT]: (notification, t) => getActionMessage(notification, t),
    [NotificationAction.EXCHANGE_REPLIES_SENT]: (notification, t) => t('notification.rfqReceived.exchangeRepliesSent', { ...notification, count: notification.meta.lineItemCount }),
    [NotificationAction.REQUEST_BULLETIN_ADDED]: (notification, t) => t('notification.rfqReceived.requestBulletinAdded', notification),
    [NotificationAction.REQUEST_BULLETINS_ADDED]: (notification, t) => t('notification.rfqReceived.requestBulletinsAdded', notification),

    [NotificationAction.REQUEST_BULLETIN_UPDATED]: (notification, t) => t('notification.rfqReceived.requestBulletinUpdated', notification),
    [NotificationAction.UPCOMING_AUCTION]: (notification, t) => t('notification.rfqReceived.upcomingAuction', notification),
    [NotificationAction.AUCTION_RESCHEDULED]: (notification, t) => t('notification.rfqReceived.auctionRescheduled', notification),
    [NotificationAction.AUCTION_REVISED]: (notification, t) => t('notification.rfqReceived.auctionRevised', notification),
    [NotificationAction.AUCTION_STARTED]: (notification, t) => t('notification.rfqReceived.auctionStarted', notification),
  },
  [NotificationDomain.RFQ_SENT]: {
    [NotificationAction.RFQ_CREATED]: (notification, t) => t('notification.rfqSent.rfqCreated', notification),
    [NotificationAction.RFQ_UPDATED]: (notification, t) => t('notification.rfqSent.rfqUpdated', notification),
    [NotificationAction.NEW_MESSAGE_RFQ]: (notification, t) => t('notification.rfqSent.newMessageRfq', notification),
    [NotificationAction.NEW_MEMBER]: (notification, t) => notification.to.userId === notification.meta.memberAffected._id
      ? t('notification.rfqSent.newMember.currentUser', notification)
      : t('notification.rfqSent.newMember.otherUser', notification),
    [NotificationAction.REMOVED_MEMBER]: (notification, t) => t('notification.rfqSent.removedMember', notification),
    [NotificationAction.RFQ_MEMBER_ROLES_UPDATED]: (notification, t) => t('notification.rfqSent.rfqMemberRolesUpdated', notification),
    [NotificationAction.NEW_RECIPIENT]: (notification, t) => t('notification.rfqSent.newRecipient', notification),
    [NotificationAction.RFQ_AWARDED]: (notification, t) => {
      const { meta } = notification;

      if (meta.awardedCompany) meta.awardedCompanies = [meta.awardedCompany];

      // Convert older notification schema
      const awardedCompanies = meta.awardedCompany
        ? [meta.awardedCompany]
        : meta.awardedCompanies;

      const companyNames = compact(map(awardedCompanies, 'name'));

      // In some rfqAwarded notifications created before 05/2023,
      // `meta.awardedCompanies` only contains empty objects (due to a bug).
      // To deal with those notifications, we render a fallback string without
      // companies.
      return isEmpty(companyNames)
        ? t('notification.rfqSent.rfqAwarded.withoutCompanies', notification)
        : t('notification.rfqSent.rfqAwarded.withCompanies', { ...notification, companyNames });
    },
    [NotificationAction.BIDS_REJECTED]: (notification, t) => t('notification.rfqSent.bidsRejected', { ...notification, count: notification.meta.numBids }),
    [NotificationAction.BIDS_REINSTATED]: (notification, t) => t('notification.rfqSent.bidsReinstated', { ...notification, count: notification.meta.numBids }),
    [NotificationAction.RFQ_CLOSED]: (notification, t) => t('notification.rfqSent.rfqClosed', notification),
    [NotificationAction.INTENTION_TO_BID]: (notification, t) => notification.meta.intent
      ? t('notification.rfqSent.intentionToBid.hasIntent', notification)
      : t('notification.rfqSent.intentionToBid.hasNoIntent', notification),
    [NotificationAction.BID_SENT]: (notification, t) => t('notification.rfqSent.bidSent', notification),
    [NotificationAction.BULLETIN_QUESTION_POSTED]: (notification, t) => t('notification.rfqSent.bulletinQuestionPosted', notification),
    [NotificationAction.BULLETIN_SIMPLE_CLARIFICATION]: (notification, t) => t('notification.rfqSent.bulletinSimpleClarificationPosted', notification),
    [NotificationAction.BULLETIN_ANSWER_TO_QUESTION_POSTED]: (notification, t) => t('notification.rfqSent.bulletinAnswerToQuestionPosted', notification),
    bulletinClarificationToQuestionPosted: (notification, t) => t('notification.rfqSent.bulletinClarificationToQuestionPosted', notification),
    [NotificationAction.RFQ_COMMENT_ADDED]: (notification, t) => {
      // Prior to June 2023, there was a bug in the 'notify-internalCommentPosted' job that
      // creates the `rfqCommentAdded` notification without an rfqSubject. In order to deal
      // with affected notifications, we fall back to an alternative text that doesn't use it.
      if (notification.meta.rfqSubject) {
        return t('notification.rfqSent.rfqCommentAdded.withSubject', notification);
      } else {
        return t('notification.rfqSent.rfqCommentAdded.withoutSubject', notification);
      }
    },
    [NotificationAction.EXCHANGE_REPLY_SENT]: (notification, t) => getActionMessage(notification, t),
    [NotificationAction.EXCHANGE_REPLIES_SENT]: (notification, t) => t('notification.rfqSent.exchangeRepliesSent', { ...notification, count: notification.meta.lineItemCount }),
    [NotificationAction.APPROVAL_REQUESTED]: (notification, t) => t('notification.rfqSent.approvalRequested', notification),
    [NotificationAction.APPROVAL_DECIDED]: (notification, t) => {
      const approvalStatus = notification.meta.approvalStatus === 'approved'
        ? 'approved'
        : 'rejected';
      const scope = notification.meta.stageName || notification.meta.isDraftApproval
        ? 'stage'
        : 'request';

      return t(`notification.rfqSent.approvalDecided.${approvalStatus}.${scope}`, notification);
    },
    [NotificationAction.APPROVAL_CANCELLED]: (notification, t) => t('notification.rfqSent.approvalCancelled', notification),
    [NotificationAction.UPCOMING_AUCTION]: (notification, t) => t('notification.rfqSent.upcomingAuction', notification),
    [NotificationAction.AUCTION_STARTED]: (notification, t) => t('notification.rfqSent.auctionStarted', notification),
    [NotificationAction.EVALUATION_REMINDER]: (notification, t) => t('notification.rfqSent.evaluationReminder', notification),
    [NotificationAction.REQUEST_SUPPLIER_EXCHANGE_DEF_ADDED]: (notification, t) => {
      if (notification.meta.exchangeType === ExchangeType.LINE_ITEM) {
        return t('notification.rfqSent.requestSupplierLineItemAdded', notification);
      } else if (documentExchangeTypes.includes(notification.meta.exchangeType)) {
        return t('notification.rfqSent.requestSupplierDocumentAdded', notification);
      } else {
        return undefined;
      }
    },
    [NotificationAction.REQUEST_SUPPLIER_EXCHANGE_DEF_UPDATED]: (notification, t) => {
      if (notification.meta.exchangeType === ExchangeType.LINE_ITEM) {
        return t('notification.rfqSent.requestSupplierLineItemUpdated', notification);
      } else if (documentExchangeTypes.includes(notification.meta.exchangeType)) {
        return t('notification.rfqSent.requestSupplierDocumentUpdated', notification);
      } else {
        return undefined;
      }
    },
  },
  [NotificationDomain.TEAM_MANAGEMENT]: {
    [NotificationAction.MEMBERSHIP_REQUEST_DECISION]: (notification, t) => {
      switch (notification.meta.requestDecision) {
        case 'accept': return t('notification.teamManagement.requestDecision.accept', notification);
        case 'reject': return t('notification.teamManagement.requestDecision.reject', notification);
        default: return t('notification.teamManagement.requestDecision.cancel', notification);
      }
    },
    [NotificationAction.REQUESTED_TO_JOIN]: (notification, t) => t('notification.teamManagement.requestedToJoin', notification),
    [NotificationAction.USER_PERMISSION_CHANGED]: (notification, t) => t('notification.teamManagement.userPermissionChanged', notification),
    [NotificationAction.USER_REMOVED_FROM_TEAM]: (notification, t) => t('notification.teamManagement.userRemovedFromTeam', notification),
    [NotificationAction.USER_ADDED_TO_TEAM]: ({ from, meta }, t: TFunction) => t('notification.teamManagement.userAddedToTeam', { from, invitee: meta.memberAffected.name || meta.memberAffected.email }),
  },
  [NotificationDomain.SUPPLIER_MANAGEMENT]: {
    [NotificationAction.SUPPLIER_INVITED_CREATES_ACCOUNT]: (notification, t) => t('notification.supplierManagement.supplierInvitedCreatesAccount', notification),
  },
  [NotificationDomain.CLIENT_COMPANIES]: {
    [NotificationAction.CLIENT_COMPANY_REQUEST_SENT]: (notification, t) => t('notification.clientCompanies.buyerRequestedToAddClient', notification),
    [NotificationAction.CLIENT_COMPANY_CLIENT_REPLIED_TO_REQUEST]: (notification, t) => notification.meta.responseIsAccept
      ? t('notification.clientCompanies.clientRepliedToRequest.accepted', notification)
      : t('notification.clientCompanies.clientRepliedToRequest.rejected', notification),
  },
  [NotificationDomain.PQQ_RECEIVED]: {
    [NotificationAction.PQQ_ISSUED]: (notification, t) => t('notification.pqqReceived.pqqIssued', notification),
  },
  [NotificationDomain.PQQ_SENT]: {
    [NotificationAction.PQQ_ACCESS_GRANTED]: (notification, t) => t('notification.pqqSent.pqqAccessGranted', notification),
  },
  [NotificationDomain.CONTRACT_RECEIVED]: {
    [NotificationAction.CONTRACT_SENT]: (notification, t) => t('notification.contractReceived.contractSent', notification),
    [NotificationAction.CONTRACT_TERMINATED]: (notification, t) => t('notification.contractReceived.contractTerminated', notification),
    [NotificationAction.CONTRACT_FAILED]: (notification, t) => t('notification.contractReceived.contractFailed', notification),
    [NotificationAction.CONTRACT_REVISED]: (notification, t) => t('notification.contractReceived.contractRevised', notification),
    [NotificationAction.CONTRACT_AMENDED]: (notification, t) => t('notification.contractReceived.contractAmended', notification),
    [NotificationAction.CONTRACT_EXCHANGE_REPLY_SENT]: (notification, t) => getActionMessage(notification, t),
    [NotificationAction.CONTRACT_TEAM_MEMBER_ADDED]: (notification, t) => t('notification.contractReceived.teamMemberAdded', notification),
    [NotificationAction.CONTRACT_TEAM_MEMBER_REMOVED]: (notification, t) => t('notification.contractReceived.teamMemberRemoved', notification),
    [NotificationAction.CONTRACT_TEAM_MEMBER_ROLES_UPDATED]: (notification, t) => t('notification.contractReceived.teamMemberRolesUpdated', notification),
    [NotificationAction.CONTRACT_E_SIGNATURE_REQUIRED]: (notification, t) => t('notification.contractReceived.eSignatureRequired', notification),
    [NotificationAction.CONTRACT_E_SIGNATURE_COMPLETE]: (notification, t) => t(
      'notification.contractReceived.eSignatureComplete',
      {
        ...notification,
        newContractStatusLabel: toLower(t(`status.${notification.meta.newContractStatus}`, { ns: 'contracts' })),
      },
    ),
    [NotificationAction.CONTRACT_E_SIGNATURE_REMINDER]: (notification, t) => t('notification.contractReceived.eSignatureReminder', notification),
    [NotificationAction.CONTRACT_E_SIGNATURE_REJECTED]: (notification, t) => t('notification.contractReceived.eSignatureRejected', notification),
  },
  [NotificationDomain.CONTRACT_SENT]: {
    [NotificationAction.CONTRACT_SENT]: (notification, t) => t('notification.contractSent.contractSent', notification),
    [NotificationAction.CONTRACT_TERMINATED]: (notification, t) => t('notification.contractSent.contractTerminated', notification),
    [NotificationAction.CONTRACT_FAILED]: (notification, t) => t('notification.contractSent.contractFailed', notification),
    [NotificationAction.CONTRACT_REVISED]: (notification, t) => t('notification.contractSent.contractRevised', notification),
    [NotificationAction.CONTRACT_AMENDED]: (notification, t) => t('notification.contractSent.contractAmended', notification),
    [NotificationAction.CONTRACT_EXCHANGE_REPLY_SENT]: (notification, t) => getActionMessage(notification, t),
    [NotificationAction.CONTRACT_PAGE_APPROVALS]: (notification, t) => {
      const { meta } = notification;
      if (meta.exchangeType === ExchangeType.APPROVAL) {
        if (meta.actionType === ActionType.ACCEPT) {
          return t('notification.contractSent.pageApproval.contractPageApproved', notification);
        }

        if (meta.actionType === ActionType.REJECT) {
          return t('notification.contractSent.pageApproval.contractPageRevoked', notification);
        }
      }

      if (meta.needsReApproval) {
        return t('notification.contractSent.pageApproval.needsReApproval', notification);
      }

      if (meta.isReadyForApproval) {
        return t('notification.contractSent.pageApproval.isReadyForApproval', notification);
      }

      const message = `Could not find message for approval notifications with "${meta.actionType}" and exchange type "${meta.exchangeType}"`;
      throw new DsError(message, { actionType: meta.actionType, exchangeType: meta.exchangeType });
    },
    [NotificationAction.CONTRACT_TEAM_MEMBER_ADDED]: (notification, t) => notification.meta.contractName
      ? t('notification.contractSent.teamMemberAdded', notification)
      : t('notification.contractSent.teamMemberAddedUntitled'),
    [NotificationAction.CONTRACT_TEAM_MEMBER_REMOVED]: (notification, t) => notification.meta.contractName
        ? t('notification.contractSent.teamMemberRemoved', notification)
        : t('notification.contractSent.teamMemberRemovedUntitled'),
    [NotificationAction.CONTRACT_TEAM_MEMBER_ROLES_UPDATED]: (notification, t) => notification.meta.contractName
        ? t('notification.contractSent.teamMemberRolesUpdated', notification)
        : t('notification.contractSent.teamMemberRolesUpdatedUntitled', notification),
    [NotificationAction.CONTRACT_E_SIGNATURE_APPROVAL_REQUIRED]: (notification, t) => t('notification.contractSent.eSignatureApprovalRequired', notification),
    [NotificationAction.CONTRACT_E_SIGNATURE_REQUIRED]: (notification, t) => t('notification.contractSent.eSignatureRequired', notification),
    [NotificationAction.CONTRACT_E_SIGNATURE_COMPLETE]: (notification, t) => t(
      'notification.contractSent.eSignatureComplete',
      {
        ...notification,
        newContractStatusLabel: toLower(t(`status.${notification.meta.newContractStatus}`, { ns: 'contracts' })),
      },
    ),
    [NotificationAction.CONTRACT_E_SIGNATURE_REMINDER]: (notification, t) => t('notification.contractSent.eSignatureReminder', notification),
    [NotificationAction.CONTRACT_E_SIGNATURE_REJECTED]: (notification, t) => t('notification.contractSent.eSignatureRejected', notification),
  },
  [NotificationDomain.QUESTIONNAIRE_RECEIVED]: {
    [NotificationAction.QUESTIONNAIRE_RECEIVED]: (notification, t) => t('notification.questionnaireReceived.questionnaireReceived', notification),
    [NotificationAction.QUESTIONNAIRE_APPROVED]: (notification, t) => t('notification.questionnaireReceived.questionnaireApproved', notification),
    [NotificationAction.QUESTIONNAIRE_REJECTED]: (notification, t) => t('notification.questionnaireReceived.questionnaireRejected', notification),
    [NotificationAction.QUESTIONNAIRE_MARKED_AS_OBSOLETE]: (notification, t) => t('notification.questionnaireReceived.questionnaireMarkedAsObsolete', notification),
    [NotificationAction.QUESTIONNAIRE_REOPENED]: (notification, t) => t('notification.questionnaireReceived.questionnaireReopened', notification),
    [NotificationAction.QUESTIONNAIRE_RESUBMISSION_REQUESTED]: (notification, t) => t('notification.questionnaireReceived.questionnaireResubmissionRequested', notification),
    [NotificationAction.QUESTIONNAIRE_TEAM_MEMBER_ADDED]: (notification, t) => t('notification.questionnaireReceived.teamMemberAdded', notification),
    [NotificationAction.QUESTIONNAIRE_TEAM_MEMBER_ROLES_UPDATED]: (notification, t) => t('notification.questionnaireReceived.teamMemberRolesUpdated', notification),
    [NotificationAction.QUESTIONNAIRE_TEAM_MEMBER_REMOVED]: (notification, t) => t('notification.questionnaireReceived.teamMemberRemoved', notification),
    [NotificationAction.QUESTIONNAIRE_DOCUMENT_EXPIRED]: (notification, t) => t('notification.questionnaireReceived.documentExpired', notification),
    [NotificationAction.QUESTIONNAIRE_UPCOMING_DOCUMENT_EXPIRY]: (notification, t) => t('notification.questionnaireReceived.upcomingDocumentExpiry', notification),
    [NotificationAction.QUESTIONNAIRE_REVISED]: (notification, t) => notification.meta.hasStatusChanged
        ? t('notification.questionnaireReceived.questionnaireRevisedWithStatusChange', notification)
        : t('notification.questionnaireReceived.questionnaireRevisedWithNewActions', notification),
    [NotificationAction.QUESTIONNAIRE_NAME_UPDATED]: (notification, t) => t('notification.questionnaireReceived.nameUpdated', notification),
    [NotificationAction.QUESTIONNAIRE_RENEWAL_REQUIRED]: (notification, t) => t('notification.questionnaireReceived.renewalRequired', notification),
    [NotificationAction.QUESTIONNAIRE_UPCOMING_RENEWAL]: (notification, t) => t('notification.questionnaireReceived.upcomingRenewal', notification),
    [NotificationAction.QUESTIONNAIRE_MORE_INFO_REQUIRED]: (notification, t) => t('notification.questionnaireReceived.moreInfoRequired', notification),
  },
  [NotificationDomain.QUESTIONNAIRE_SENT]: {
    [NotificationAction.QUESTIONNAIRE_ACCEPTED]: (notification, t) => t('notification.questionnaireSent.questionnaireAccepted', notification),
    [NotificationAction.QUESTIONNAIRE_DECLINED]: (notification, t) => t('notification.questionnaireSent.questionnaireDeclined', notification),
    [NotificationAction.QUESTIONNAIRE_SUBMITTED]: (notification, t) => t('notification.questionnaireSent.questionnaireSubmitted', notification),
    [NotificationAction.QUESTIONNAIRE_UPDATED]: (notification, t) => t('notification.questionnaireSent.questionnaireUpdated', notification),
    [NotificationAction.QUESTIONNAIRE_TEAM_MEMBER_ADDED]: (notification, t) => t('notification.questionnaireSent.teamMemberAdded', notification),
    [NotificationAction.QUESTIONNAIRE_TEAM_MEMBER_ROLES_UPDATED]: (notification, t) => t('notification.questionnaireSent.teamMemberRolesUpdated', notification),
    [NotificationAction.QUESTIONNAIRE_TEAM_MEMBER_REMOVED]: (notification, t) => t('notification.questionnaireSent.teamMemberRemoved', notification),
    [NotificationAction.QUESTIONNAIRE_DOCUMENT_EXPIRED]: (notification, t) => t('notification.questionnaireSent.documentExpired', notification),
    [NotificationAction.QUESTIONNAIRE_EXPIRED]: (notification, t) => t('notification.questionnaireSent.expired', notification),
    [NotificationAction.QUESTIONNAIRE_UPCOMING_DOCUMENT_EXPIRY]: (notification, t) => t('notification.questionnaireSent.upcomingDocumentExpiry', notification),
    [NotificationAction.QUESTIONNAIRES_REVISION_COMPLETED]: (notification, t) => t('notification.questionnaireSent.questionnairesRevisionCompleted', notification),
    [NotificationAction.QUESTIONNAIRES_SEND_COMPLETED]: (notification, t) => t('notification.questionnaireSent.questionnairesSendCompleted', notification),
    [NotificationAction.QUESTIONNAIRE_RENEWAL_REQUIRED]: (notification, t) => t('notification.questionnaireSent.renewalRequired', notification),
    [NotificationAction.QUESTIONNAIRE_UPCOMING_RENEWAL]: (notification, t) => t('notification.questionnaireSent.upcomingRenewal', notification),
  },
  [NotificationDomain.USER_REPORT]: {
    [NotificationAction.USER_REPORT_COMPLETED]: (notification, t) => t('notification.userReport.userReportCompleted', notification),
    [NotificationAction.USER_REPORT_FAILED]: (notification, t) => t('notification.userReport.userReportFailed', notification),
  },
};

export const getNotificationMessage = (notification, t) => {
  if (messagesNotificationTable[notification.domain]?.[notification.action]) {
    return messagesNotificationTable[notification.domain]?.[notification.action](notification, t);
  }

  throw new RenderNotificationError(
    `Could not find config for rendering notification message ${notification.domain}|${notification.action}`,
    { actionType: notification.meta.actionType, exchangeType: notification.meta.exchangeType },
  );
};
