import { RequestsReportingConfig } from '@deepstream/common/reporting';
import { PanelText } from '@deepstream/ui-kit/elements/Panel';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { AllRequestsDataReportingGrid } from './AllRequestsReportingGrid';
import * as dashboard from './dashboard';
import { useRequestsReportingTableData } from './useRequestsReporting';

export const AllRequestDataTabContent = ({
  config,
  useData = useRequestsReportingTableData,
}: {
  config: RequestsReportingConfig;
  useData?: typeof useRequestsReportingTableData;
}) => {
  const { t } = useTranslation('translation');
  const { data, isLoading, isError, isSuccess } = useData('allRequests', config);

  return (
    <dashboard.Card isLoading={isLoading} isError={isError} sx={{ height: '100%', minHeight: '373px' }}>
      {isSuccess && data ? (
        isEmpty(data) ? (
          <>
            <dashboard.CardHeading p="20px 20px 16px" heading={t('general.request_other')} />
            <PanelText pt={0}>{t('table.noDataToDisplay')}</PanelText>
          </>
        ) : (
          <AllRequestsDataReportingGrid data={data} config={config} />
        )
      ) : null}
    </dashboard.Card>
  );
};
