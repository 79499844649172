export type RequestTag = {
  _id?: string;
  name?: string;
  parentTagId?: string;
  companyId?: string;
  requestIds?: string[];
  contractIds?: string[];
};

export const getRequestTagAncestorsNames = (requestTag: RequestTag, tagsById: { [id: string]: RequestTag }): string[] => {
  // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
  const parentTag = tagsById[requestTag.parentTagId];
  if (!parentTag) {
    return [];
  }
  return [...getRequestTagAncestorsNames(parentTag, tagsById), parentTag.name];
};
