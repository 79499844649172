import { isEmpty, isFinite, orderBy } from 'lodash';
import { useCallback } from 'react';
import { LabeledSortCriterion, LabeledSortDirection } from '@deepstream/ui-utils';
import { SortingDirection } from '@deepstream/common';
import { EditableGridColumn } from './utils';
import { getSortCellValue } from './useSortedGridData';

export const getValidSorting = <TRow,>(sorting: {
  criterion: LabeledSortCriterion;
  direction: LabeledSortDirection;
}[],
  columns: EditableGridColumn<TRow>[],
) => {
  return sorting.filter(({ criterion, direction }) => (
    criterion.accessor &&
    columns.find(column => column._id === criterion.accessor) &&
    direction.direction
  ));
};

export const useMultiSortGridRows = <TRow,>(
  columns: EditableGridColumn<TRow>[],
  sorting: {
    criterion: LabeledSortCriterion;
    direction: LabeledSortDirection;
  }[],
) => {
  return useCallback((rows: TRow[]) => {
    const validSorting = getValidSorting(sorting, columns);

    if (isEmpty(validSorting)) {
      return rows;
    } else {
      const {
        iteratees,
        orders,
      } = validSorting.reduce((result, sorting) => {
        const column = columns.find(column => column._id === sorting.criterion.accessor)!;

        if (column.sortType === 'caseInsensitive') {
          const iteratee = (row: TRow) => {
            const value = getSortCellValue(row, column);

            return value ? value.toLowerCase() : '';
          };

          result.iteratees.push(iteratee);
          result.orders.push(sorting.direction.direction);
        } else if (column.sortType === 'datetime') {
          const iteratee = (row: TRow) => {
            const value = getSortCellValue(row, column);

            const parsedDate = new Date(value);

            return value && isFinite(parsedDate.valueOf()) ? parsedDate : new Date(0);
          };

          result.iteratees.push(iteratee);
          result.orders.push(sorting.direction.direction);
        } else {
          const iteratee = (row: TRow) => getSortCellValue(row, column);

          result.iteratees.push(iteratee);
          result.orders.push(sorting.direction.direction);
        }

        return result;
      }, {
        iteratees: [] as ((row: TRow) => unknown)[],
        orders: [] as SortingDirection[],
      });

      return orderBy(
        rows,
        iteratees,
        orders,
      );
    }
 }, [columns, sorting]);
};
