import { format, formatInTimeZone, OptionsWithTZ } from 'date-fns-tz';
import enGB from 'date-fns/locale/en-GB';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import pt from 'date-fns/locale/pt';
import zhCN from 'date-fns/locale/zh-CN';
import { isEmpty, isFinite } from 'lodash';
import { currencyCodeToSymbol } from './getCurrencySymbol';

/**
 * Adjusts the formatting options passed to `react-number-format` to the
 * provided locale.
 *
 * NB Currently only supports English and French.
 */
export const adjustNumberFormatOptionsToLocale = (options: Record<string, any>, locale: string) => {
  switch (locale) {
    case 'fr-FR':
    case 'pt-PT':
      return {
        ...options,
        thousandSeparator: ' ',
        decimalSeparator: ',',
      };
    case 'es-ES':
      return {
        ...options,
        thousandSeparator: '.',
        decimalSeparator: ',',
      };
    default:
      return options;
  }
};

export const getDatePickerTimeFormat = (locale: string) => {
  switch (locale) {
    case 'es-ES':
    case 'fr-FR': return 'HH:mm';
    case 'pt-PT': return 'HH\'h\'mm';
    default: return 'hh:mm a';
  }
};

export const getDateFnsLocale = (locale?: string) => {
  switch (locale) {
    case 'es-ES': return es;
    case 'fr-FR': return fr;
    case 'pt-PT': return pt;
    case 'zh-CN': return zhCN;
    default: return enGB;
  }
};

export enum DateFormat {
  MMM = 'MMM', // scatter chart
  QQQ = 'QQQ', // scatter chart
  D_MMM = 'd MMM', // scatter chart
  YY = 'yy', // scatter chart
  ZZZ = 'zzz',
  D_MMM_YYYY = 'd MMM yyyy',
  DD_MMM_YYYY = 'dd MMM yyyy',
  EEE_DD_MMM_YYYY = 'EEE dd MMM yyyy',
  DD_MMM_YYYY_HH_MM = 'dd MMM yyyy HH:mm',
  DD_MMM_YYYY_HH_MM_SS = 'dd MMM yyyy HH:mm:ss',
  DD_MMM_YYYY_HH_MM_A = 'dd MMM yyyy hh:mm a',
  DD_MMM_YYYY_HH_MM_A_ZZZ = 'dd MMM yyyy hh:mm a zzz',
  DD_MMM_YYYY_HH_MM_SS_ZZZ = 'dd MMM yyyy HH:mm:ss zzz',
  DDMMYY = 'ddMMyy',
  DDMMYY_HHMM = 'ddMMyy HHmm',
  DDMMYY_HHMMSS = 'ddMMyy HHmmss',
  MMMM_YYYY = 'MMMM yyyy',
  MMM_D_YYYY = 'MMM d, yyyy',

  HH_MM_B_O = 'hh:mm b O',
  MM_SS = 'mm:ss',
  H_MM_A = 'h:mm a',
  HH_MM_A = 'hh:mm a',
  H_MM_A_ZZZ = 'h:mm a zzz',
  HH_MM_A_ZZZ = 'hh:mm a zzz',

  DD_MM_YYYY_DASH = 'dd-MM-yyyy',
  YYYY_MM_DD_DASH = 'yyyy-MM-dd',
  YYYY_MM_DD_HH_MM_SS_DASH = 'yyyy-MM-dd HH:mm:ss',
  DD_MM_YYYY_HH_MM_A_ZZZ_DASH = 'dd-MM-yyyy hh:mm a zzz',
  YYYY_MM_DD_HH_MM_A_ZZZ_DASH = 'yyyy-MM-dd hh:mm a zzz',

  MM_DD_YYYY_HH_MM_A_ZZZ_SLASH = 'MM/dd/yyyy hh:mm a zzz',
  DD_MM_YYYY_HH_MM_A_SLASH = 'dd/MM/yyyy hh:mm a',
  DD_MM_YYYY_HH_MM_SLASH = 'dd/MM/yyyy HH:mm',
  DD_MM_YYYY_HH_MM_SS_SLASH = 'dd/MM/yyyy HH:mm:ss',
  MM_DD_YYYY_SLASH = 'MM/dd/yyyy',
  D_M_YY_H_MM_A_SLASH = 'd/M/yy h:mm a',
  DD_MM_YY_SLASH = 'dd/MM/yy',
  DD_MM_YYYY_SLASH = 'dd/MM/yyyy',
  YYYY_MM_DD_SLASH = 'yyyy/MM/dd',
}

const chineseDateFormats = {
  [DateFormat.MMM]: 'MMM',
  [DateFormat.YY]: 'yy年',
  [DateFormat.ZZZ]: 'zzz',
  [DateFormat.QQQ]: 'QQQ',
  [DateFormat.D_MMM]: 'MMMd日',

  [DateFormat.D_MMM_YYYY]: 'yyyy年MMMd日',
  [DateFormat.DD_MMM_YYYY]: 'yyyy年MMMdd日',
  [DateFormat.DD_MMM_YYYY_HH_MM]: 'yyyy年MMMdd日 HH:mm',
  [DateFormat.DD_MMM_YYYY_HH_MM_SS]: 'yyyy年MMMdd日 HH:mm:ss',
  [DateFormat.DD_MMM_YYYY_HH_MM_A]: 'yyyy年MMMdd日 hh:mma',
  [DateFormat.DD_MMM_YYYY_HH_MM_A_ZZZ]: 'yyyy年MMMdd日 hh:mma zzz',
  [DateFormat.DDMMYY_HHMM]: 'ddMMyy HHmm',
  [DateFormat.DDMMYY_HHMMSS]: 'ddMMyy HHmmss',
  [DateFormat.MMMM_YYYY]: 'MMMMyyyy年',
  [DateFormat.MMM_D_YYYY]: 'MMMd日, yyyy年',

  [DateFormat.HH_MM_B_O]: 'hh:mm b O',
  [DateFormat.MM_SS]: 'mm:ss',
  [DateFormat.H_MM_A]: 'h:mma',
  [DateFormat.HH_MM_A]: 'hh:mma',
  [DateFormat.H_MM_A_ZZZ]: 'h:mma zzz',
  [DateFormat.HH_MM_A_ZZZ]: 'hh:mma zzz',

  [DateFormat.DD_MM_YYYY_DASH]: 'dd-MM-yyyy',
  [DateFormat.YYYY_MM_DD_DASH]: 'yyyy-MM-dd',
  [DateFormat.YYYY_MM_DD_HH_MM_SS_DASH]: 'yyyy-MM-dd HH:mm:ss',
  [DateFormat.DD_MM_YYYY_HH_MM_A_ZZZ_DASH]: 'dd-MM-yyyy hh:mma zzz',
  [DateFormat.YYYY_MM_DD_HH_MM_A_ZZZ_DASH]: 'yyyy-MM-dd hh:mma zzz',

  [DateFormat.MM_DD_YYYY_HH_MM_A_ZZZ_SLASH]: 'MM/dd/yyyy hh:mma zzz',
  [DateFormat.DD_MM_YYYY_HH_MM_A_SLASH]: 'dd/MM/yyyy hh:mma',
  [DateFormat.DD_MM_YYYY_HH_MM_SLASH]: 'dd/MM/yyyy HH:mm',
  [DateFormat.DD_MM_YYYY_HH_MM_SS_SLASH]: 'dd/MM/yyyy HH:mm:ss',
  [DateFormat.MM_DD_YYYY_SLASH]: 'MM/dd/yyyy',
  [DateFormat.D_M_YY_H_MM_A_SLASH]: 'd/M/yy h:mma',
  [DateFormat.DD_MM_YY_SLASH]: 'dd/MM/yy',
  [DateFormat.DD_MM_YYYY_SLASH]: 'dd/MM/yyyy',
};

export const adjustDateFnsFormatToLocale = (format: DateFormat, locale: string | undefined) => {
  switch (locale) {
    case 'es-ES':
    case 'fr-FR':
      return format.replace(' a', '').replace(/h/g, 'H');
    case 'pt-PT':
      return format.replace(' a', '').replace(/h/g, 'H').replace(':', '\'h\'');
    case 'zh-CN':
      return chineseDateFormats[format] || format;
    default: return format;
  }
};

export type DateFormatOptions = Omit<OptionsWithTZ, 'locale'> & { locale?: string };

export const localeFormatDate = (date: number | Date, dateFormat: DateFormat, options: DateFormatOptions = {}) => {
  const dateFnsLocale = getDateFnsLocale(options.locale);
  const localizedFormat = adjustDateFnsFormatToLocale(dateFormat, options.locale);

  return format(date, localizedFormat, { ...options, locale: dateFnsLocale });
};

export const localeFormatDateTz = (date: number | Date, dateFormat: DateFormat, options: DateFormatOptions = {}) => {
  const timeZone = options.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;

  const dateFnsLocale = getDateFnsLocale(options.locale);
  const localizedFormat = adjustDateFnsFormatToLocale(dateFormat, options.locale);

  return formatInTimeZone(date, timeZone, localizedFormat, { ...options, locale: dateFnsLocale });
};

/**
 * This should be used to render all prices, even when no currency symbol
 * or currency code is to be rendered, to make sure that currency-dependent
 * settings like the number of fractional digits are accounted for.
 */
export const localeFormatPrice = (
  amount: number,
  currencyCode: string | undefined,
  options: Intl.NumberFormatOptions & {
    locale: string,
    showCode?: boolean;
    hideSeparators?: boolean;
    hideCurrency?: boolean;
    decimalPlaces?: number;
  },
) => {
  const formatter = new Intl.NumberFormat(options.locale, {
    // Intl.NumberFormat throws an error if we don't pass a currency.
    // To allow rendering of prices where `hideCurrency` is `true`
    // and no currency is set, we assume 'USD', causing the amount to get
    // formatted with 2 fractional digits.
    currency: currencyCode || 'USD',
    style: 'currency',
    currencyDisplay: options.showCode || !currencyCode || !currencyCodeToSymbol[currencyCode]
      ? 'code'
      : 'narrowSymbol',
    minimumFractionDigits: options.decimalPlaces,
    maximumFractionDigits: options.decimalPlaces,
    notation: options.notation,
  });

  const partsToExclude: string[] = [];

  if (options.hideSeparators) {
    partsToExclude.push('group');
  }
  if (options.hideCurrency || !currencyCode) {
    partsToExclude.push('literal', 'currency');
  }

  if (isEmpty(partsToExclude)) {
    return formatter.format(amount);
  } else {
    const parts = formatter.formatToParts(amount);

    return parts
      .filter(part => !partsToExclude.includes(part.type))
      .map(part => part.value)
      .join('');
  }
};

export type LocaleFormatNumberOptions = Intl.NumberFormatOptions & { locale: string, decimalPlaces?: number };

export const localeFormatNumber = (number: number, { locale, decimalPlaces, ...options }: LocaleFormatNumberOptions) =>
  number.toLocaleString(locale, {
    maximumFractionDigits: 10,
    ...options,
    ...(
      typeof decimalPlaces === 'number' && {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
      }
    ),
  });

/**
 * Convert a value to a percentage string.
 *
 * Notes:
 *   - multiplies by 100;
 *   - rounds to the closest 2 decimal points;
 *   - NaN and other values that are not finite numbers convert to em-dash.
 */
export const localeFormatFactorAsPercent = (
  value: number | undefined,
  { locale, decimalPlaces = 2 }: { locale: string; decimalPlaces?: number },
) => {
  if (!isFinite(value)) {
    return '—';
  }

  const formattedNumber = localeFormatNumber(
    (value as number) * 100,
    { locale, decimalPlaces },
  );

  return `${formattedNumber}%`;
};

/**
 * Returns the primary subtag of a language tag.
 *
 * @example getPrimarySubtag('en') // 'en'
 * @example getPrimarySubtag('en-GB') // 'en'
 * @example getPrimarySubtag(undefined) // undefined
 */
export const getPrimarySubtag = (locale?: string) =>
  locale ? locale.split('-')[0] : locale;

export const localeCapitalize = (value: string, locale: string) => {
  if (!value) {
    return value;
  }

  const firstLetter = value.charAt(0).toLocaleUpperCase(locale);
  return `${firstLetter}${value.slice(1)}`;
};

export const localeSort = <T>(array: T[], locale: string, key: (item: T) => string) =>
  [...array].sort((a, b) => key(a).localeCompare(key(b), locale));
