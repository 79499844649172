import { useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { Questionnaire } from '@deepstream/common/preQual';

import { useApi, wrap } from '../../../api';
import { useCurrentCompanyId } from '../../../currentCompanyId';
import { ExchangeSnapshot } from '../../../types';
import { useQuestionnaireData, useQuestionnaireId } from './questionnaireUtils';
import { usePopulateExchangeActionDisplayProps } from '../../../ExchangeModal/usePopulateExchangeActionDisplayProps';

export const getAdminQuestionnaireQueryKey = ({
  questionnaireId,
}: {
  questionnaireId: string;
}) =>
  ['adminQuestionnaire', { questionnaireId }];

export const useAdminQuestionnaireQueryKey = () => {
  const questionnaireId = useQuestionnaireId({ required: true });
  return useMemo(() => getAdminQuestionnaireQueryKey({ questionnaireId }), [questionnaireId]);
};

export const getQuestionnaireQueryKey = ({
  questionnaireId,
  currentCompanyId,
}: {
  questionnaireId: string;
  currentCompanyId: string;
}) => [
  'questionnaire',
  { questionnaireId, currentCompanyId },
];

export const useQuestionnaireQueryKey = ({
  questionnaireId: questionnaireIdProp,
  currentCompanyId: currentCompanyIdProp,
}: {
  questionnaireId?: string;
  currentCompanyId?: string;
} = {}) => {
  const providedCurrentCompanyId = useCurrentCompanyId({ required: false });
  const currentCompanyId = currentCompanyIdProp || providedCurrentCompanyId;

  if (!currentCompanyId) {
    throw new Error('A `currentCompanyId` is required');
  }

  const providedQuestionnaireId = useQuestionnaireId({ required: false });
  const questionnaireId = questionnaireIdProp || providedQuestionnaireId;

  if (!questionnaireId) {
    throw new Error('A `questionnaireId` is required');
  }

  const queryKey = useMemo(
    () => getQuestionnaireQueryKey({ questionnaireId, currentCompanyId }),
    [questionnaireId, currentCompanyId],
  );

  return queryKey;
};

export const useQuestionnaire = ({
  questionnaireId,
  currentCompanyId,
  enabled = true,
}: {
  questionnaireId?: string;
  currentCompanyId?: string;
  enabled?: boolean;
} = {}) => {
  const queryKey = useQuestionnaireQueryKey({
    currentCompanyId,
    questionnaireId,
  });

  const api = useApi();
  const query = useQuery(
    queryKey,
    wrap(api.getQuestionnaire),
    {
      enabled,
      staleTime: 60 * 1000,
    },
  );

  return useMemo(
    () => ({ ...query, queryKey }),
    [query, queryKey],
  );
};

export const useQuestionnaireExchange = ({
  exchangeId,
  enabled = true,
  onSuccess,
}: {
  exchangeId: string;
  enabled?: boolean;
  onSuccess?: any;
}) => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { _id: questionnaireId, recipient } = useQuestionnaireData();
  const api = useApi();
  const queryClient = useQueryClient();
  const populateExchangeActionDisplayProps = usePopulateExchangeActionDisplayProps();
  const questionnaireQueryKey = useQuestionnaireQueryKey();

  const queryKey = useMemo(
    () => [
      'questionnaireExchange',
      { questionnaireId, exchangeId, currentCompanyId },
    ],
    [questionnaireId, exchangeId, currentCompanyId],
  );

  const recipientId = recipient._id;

  const query = useQuery(
    queryKey,
    wrap(api.getQuestionnaireExchange),
    {
      staleTime: 10 * 1000,
      onSuccess,
      enabled: Boolean(exchangeId) && enabled,
      select: (exchange: ExchangeSnapshot) => populateExchangeActionDisplayProps(exchange, recipientId),
      initialData: () =>
        queryClient
          .getQueryData<Questionnaire>(questionnaireQueryKey)
          ?.exchangeById[exchangeId] as ExchangeSnapshot | undefined,
    },
  );

  return useMemo(
    () => ({ ...query, queryKey }),
    [query, queryKey],
  );
};

export const useQuestionnaireExchangeAttachmentDownloads = ({
  exchangeId,
}: {
  exchangeId: string;
}) => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { _id: questionnaireId } = useQuestionnaireData();
  const api = useApi();

  const queryKey = useMemo(
    () => [
      'questionnaireExchangeAttachmentDownloads',
      { questionnaireId, exchangeId, currentCompanyId },
    ],
    [questionnaireId, exchangeId, currentCompanyId],
  );

  const query = useQuery(
    queryKey,
    wrap(api.getQuestionnaireExchangeAttachmentDownloads),
    {
      staleTime: 10 * 1000,
      enabled: Boolean(exchangeId),
    },
  );

  return useMemo(
    () => ({ ...query, queryKey }),
    [query, queryKey],
  );
};

export const useQuestionnaireAuditQueryKey = ({
  questionnaireId: questionnaireIdProp,
  currentCompanyId: currentCompanyIdProp,
}: {
  questionnaireId?: string;
  currentCompanyId?: string;
}) => {
  const providedCurrentCompanyId = useCurrentCompanyId({ required: false });
  const currentCompanyId = currentCompanyIdProp || providedCurrentCompanyId;

  if (!currentCompanyId) {
    throw new Error('A `currentCompanyId` is required');
  }

  const providedQuestionnaireId = useQuestionnaireId({ required: false });
  const questionnaireId = questionnaireIdProp || providedQuestionnaireId;

  if (!questionnaireId) {
    throw new Error('A `questionnaireId` is required');
  }

  const queryKey = useMemo(
    () => ['questionnaireAudit', { questionnaireId, currentCompanyId }],
    [questionnaireId, currentCompanyId],
  );

  return queryKey;
};

export const useQuestionnaireAudit = ({
  questionnaireId,
  currentCompanyId,
}: {
  questionnaireId?: string;
  currentCompanyId?: string;
} = {}) => {
  const queryKey = useQuestionnaireAuditQueryKey({
    currentCompanyId,
    questionnaireId,
  });

  const api = useApi();
  const query = useQuery(
    queryKey,
    wrap(api.getQuestionnaireAudit),
  );

  return useMemo(
    () => ({ ...query, queryKey }),
    [query, queryKey],
  );
};
