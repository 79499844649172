import { Flex } from 'rebass/styled-components';
import { Attachment } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { IconButton } from '@deepstream/ui-kit/elements/button/IconButton';
import { useApi } from '../../../api';
import { useToaster } from '../../../toast';
import { useMutation } from '../../../useMutation';
import { downloadUrl } from '../../../useDownload';
import { useQuestionnaireTemplateId } from './questionnaireTemplateUtils';
import { useCurrentCompanyId } from '../../../currentCompanyId';

const useDownloadTemplateAttachment = () => {
  const { t } = useTranslation('preQualification');
  const api = useApi();
  const toaster = useToaster();
  const questionnaireTemplateId = useQuestionnaireTemplateId({ required: true });
  const currentCompanyId = useCurrentCompanyId({ required: true });

  return useMutation(
    async ({ _id: attachmentId }: Attachment) => {
      const { url } = await api.getQuestionnaireTemplateAttachmentDownloadUrl({
        questionnaireTemplateId,
        attachmentId,
        currentCompanyId,
      });
      downloadUrl(url, '');
    },
    {
      onSuccess: () => toaster.success(t('toaster.documentDownloaded.success')),
      onError: () => toaster.error(t('toaster.documentDownloaded.failed')),
    },
  );
};

export const QuestionnaireTemplateAttachment = ({
  attachment,
  truncate = false,
}: {
  attachment: Attachment;
  truncate?: boolean;
}) => {
  const [download, { isLoading }] = useDownloadTemplateAttachment();

  return (
    <Flex alignItems="center" sx={{ maxWidth: '100%' }}>
      <IconText
        isIconRegular
        gap={2}
        icon="file"
        iconColor="subtext"
        text={attachment.name}
        truncate={truncate}
      />
      <IconButton
        fixedWidth
        icon="download"
        color="primary"
        onClick={event => {
          event.stopPropagation();
          download(attachment);
        }}
        disabled={isLoading}
        ml={2}
      />
    </Flex>
  );
};
