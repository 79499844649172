import { useCallback } from 'react';
import * as Sentry from '@sentry/react';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { useToaster } from './toast';
import { useApi } from './api';

export const getContentDispositionFilename = (response: AxiosResponse<unknown>) => {
  const contentDisposition = response.headers['content-disposition'];

  if (!contentDisposition) {
    throw new Error('server response does not contain a content-disposition header');
  }

  const utf8Match = contentDisposition.match(/filename\*=UTF-8''(.+)$/);

  if (utf8Match) {
    return decodeURIComponent(utf8Match[1]);
  }

  const match = contentDisposition.match(/filename="(.+)"/);

  if (!match) {
    throw new Error(`could not find filename in content-disposition header "${contentDisposition}"`);
  }

  return match[1];
};

export const downloadUrl = (url: string, filename: string) => {
  const a = window.document.createElement('a');
  a.download = filename;
  a.href = url;
  a.click();
};

export const downloadBlob = (blob: Blob, filename: string) => {
  const url = URL.createObjectURL(blob);
  downloadUrl(url, filename);
  URL.revokeObjectURL(url);
};

const useDownload = ({
  successMessage = '',
  errorMessage = '',
} = {}) => {
  const { t } = useTranslation();
  const api = useApi();
  const toaster = useToaster();

  return useCallback(
    async (url: string, method: 'GET' | 'POST' = 'GET', data: any = undefined) => {
      try {
        const response = await api.downloadBlob({ method, url, data });
        const filename = getContentDispositionFilename(response);
        downloadBlob(response.data, filename);

        toaster.success(successMessage || t('request.download.toaster.downloadSuccess'));
        return true;
      } catch (error) {
        Sentry.captureException(error);
        toaster.error(errorMessage || t('request.download.toaster.downloadError'));
        throw error;
      }
    },
    [api, toaster, successMessage, t, errorMessage],
  );
};

export default useDownload;
