import { useMemo } from 'react';
import { localeFormatFactorAsPercent, localeFormatNumber } from '@deepstream/utils';
import { useCurrentUserLocale } from '../useCurrentUser';

export const Number = ({
  value,
  suffix,
  options,
}: {
  value?: number;
  suffix?: string;
  options?: Intl.NumberFormatOptions;
}) => {
  const locale = useCurrentUserLocale();

  return (
    <>
      {/*
       // @ts-expect-error ts(2345) FIXME: Argument of type 'number | undefined' is not assignable to parameter of type 'number'. */}
      {localeFormatNumber(value, { locale, ...options })}{suffix}
    </>
  );
};

export const FactorAsPercent = ({ value }: { value?: number }) => {
  const locale = useCurrentUserLocale();

  return (
    <>
      {localeFormatFactorAsPercent(value, { locale })}
    </>
  );
};

export const ByteSizeNumber = ({ size }: { size: number }) => {
  const locale = useCurrentUserLocale();

  const formatter = useMemo(
    () =>
      new Intl.NumberFormat(locale, {
        notation: 'compact',
        style: 'unit',
        unit: 'byte',
        unitDisplay: 'narrow',
        maximumFractionDigits: 1,
      }),
    [locale],
  );

  const formattedByteSize = useMemo(() => {
    const parts = formatter.formatToParts(size);
    let spaceAdded = false;

    return parts.reduce((result, part) => {
      const { type, value } = part;
      // Add a space between the number value and the unit
      // e.g. 47 KB
      // We look for either the first 'compact' (e.g. K, M, G) or the first unit (B)
      if (!spaceAdded && (type === 'compact' || type === 'unit')) {
        result.push(` ${value}`);
        spaceAdded = true;
      } else {
        result.push(value);
      }
      return result;
    }, [] as string[]).join('');
  }, [formatter, size]);

  return <>{formattedByteSize}</>;
};
