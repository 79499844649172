import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from '@deepstream/ui-kit/elements/button/Button';

export const DownloadXLSXButton = (props : ButtonProps) => {
  const { t } = useTranslation('general');

  return (
    <Button
      small
      variant="secondary-outline"
      iconLeft="download"
      {...props}
    >
      {t('downloadXLSX')}
    </Button>
  );
};
