import { useCallback, useMemo } from 'react';
import { pick } from 'lodash';
import { Attachment, ChangeType, ExchangeType, ExchangeUpdatedChange } from '@deepstream/common/rfq-utils';
import { MutationArgs } from '../../types';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useExchange } from '../../useExchange';
import { useExchangeRefetch } from '../../rfx';
import { useChangeBulletinExchanges } from './useChangeBulletinExchanges';
import { useBulletinSection } from './useBulletinSection';
import { useRfqId } from '../../useRfq';
import { useCurrentUser } from '../../useCurrentUser';

export const useUpdateBulletin = () => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const exchange = useExchange();
  const refetchExchange = useExchangeRefetch();
  const [changeBulletinExchanges, mutationState] = useChangeBulletinExchanges({ isBulletinUpdate: true, refetchExchange });
  const bulletinSection = useBulletinSection();
  const rfqId = useRfqId();
  const user = useCurrentUser();

  const getUpdateChange = useCallback(
    (message: string, attachments: Attachment[]): ExchangeUpdatedChange | undefined => {
      // Bulletin section is undefined for older requests, and also for current requests in which the user querying the data
      // doesn't have access to the `Messages` page
      if (!bulletinSection) return undefined;

      return {
        type: ChangeType.EXCHANGE_UPDATED,
        sectionName: bulletinSection._id,
        companyId: currentCompanyId,
        docXDef: {
          _id: exchange.def._id,
          type: ExchangeType.BULLETIN,
          message,
          attachments,
          user: pick(user, ['_id', 'name']),
        } as any,
      };
    },
    [bulletinSection, currentCompanyId, exchange.def._id, user],
  );

  const updateBulletin = useCallback(
    async (action, mutationOptions?: MutationArgs) => {
      const change = getUpdateChange(action.message, action.attachments);
      if (!change) return;
      await changeBulletinExchanges({
        rfqId,
        currentCompanyId,
        changes: [change],
      }, mutationOptions);
    },
    [changeBulletinExchanges, rfqId, currentCompanyId, getUpdateChange],
  );

  return useMemo(
    () => bulletinSection ? [
      updateBulletin,
      mutationState,
    ] as const : undefined,
    [bulletinSection, mutationState, updateBulletin],
  );
};
